import React, { useContext, useEffect, useState } from "react";
import TasklistTabs from "./TasklistTabs";
import addIcon from "../../../Assets/filter/plus-squared.svg";
import { Image } from "@nextui-org/react";
import { Outlet, useParams } from "react-router-dom";
import AddTask from "./AddTask";
import useSettings from "hooks/useSettings";
import { SettingsContext } from "Context/SettingsContext";
import DataLoader from "pages/DataLoader/DataLoader";

export default function TasklistTemplate() {
  const [openAddTask, setOpenAddTask] = useState(false);
  const { frondId } = useParams();

  const { loadSettings } = useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  const [showTask, setShowTask] = useState(false);

  useEffect(() => {
    if (!loadSettings) {
      if (frondSettings.can_edit_tasks && !frondSettings.is_owner) {
        setShowTask(true);
      } else if (frondSettings.is_owner) {
        setShowTask(true);
      } else {
        setShowTask(false);
      }
    }
  }, [loadSettings, frondSettings]);
  return (
    <>
      {loadSettings ? (
        <div className="min-h-[200px] flex justify-center items-center">
          <DataLoader />
        </div>
      ) : (
        <>
          <section className="tasklist">
            <div className="actions flex gap-2.5 justify-between xl:flex-nowrap flex-wrap mb-[15px]">
              <TasklistTabs />
              {showTask ? (
                <button
                  type="button"
                  onClick={() => {
                    setOpenAddTask(true);
                  }}
                  className="flex justify-center items-center gap-2.5 bg-mainGradiant py-1.5 px-2.5 rounded-4xl w-[111px] phone:h-[36px]"
                >
                  <Image
                    src={addIcon}
                    alt="Add Task"
                    className="w-[18px] h-[18px]"
                  />
                  <span className="text-base text-white leading-[18.4px]">
                    Add task
                  </span>
                </button>
              ) : null}
            </div>
            <Outlet />
          </section>
        </>
      )}
      <AddTask
        frondId={frondId}
        isOpen={openAddTask}
        onClose={() => {
          setOpenAddTask(false);
        }}
        isModalOpen={true}
      />
    </>
  );
}
