import { Image } from "@nextui-org/react";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import L from "leaflet";
import mapMarker from "../../Assets/create-frond/geolocation.svg";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import tempMap from "../../Assets/create-frond/temp-map.png";
import { useEffect, useState } from "react";

const ShareHeader = ({ frondData }) => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const [editLoactionModal, setEditLocationModal] = useState(false);
  const [isShowMap, setIsShowMap] = useState(true);
  const [position, setPosition] = useState([0, 0]);

  useEffect(() => {
    const handleLoad = () => {
      setIsPageLoaded(true);
    };

    if (document.readyState === "complete") {
      setTimeout(() => {
        handleLoad();
      }, 1000);
    } else {
      window.addEventListener("load", handleLoad);
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  useEffect(() => {}, [position]);

  const [mapLoaded, setMapLoaded] = useState(false);

  const handleTileLoad = () => {
    setMapLoaded(true);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const CustomMarker = () => {
    const map = useMap();
    map.setView(position, 12);

    const icon = L.divIcon({
      html: `<img src="${mapMarker}" style="width: 28px; height: 28px;" />`,
      className: "",
    });

    return <Marker position={position} icon={icon}></Marker>;
  };

  useEffect(() => {
    if (frondData?.latitude && frondData?.longitude) {
      setPosition([frondData?.latitude, frondData?.longitude]);
      setIsShowMap(false);
    }
  }, [frondData]);

  return (
    <div className="map min-w-full min-h-[303px] max-h-[303px] border-2 border-solid border-grayBorder rounded-[20px] relative no-focus mb-5">
      <div className="absolute bg-frondDetails rounded-[20px] h-[120px] w-full z-[999]">
        <div className="absolute top-5 phone:left-5 phone:right-5 left-2.5 right-2.5 flex justify-between items-center z-30 phone:gap-[30px] gap-2.5">
          <div className="flex phone:gap-2.5 gap-[5px] items-center overflow-hidden">
            <span className="phone:text-lg text-[12px] font-bold leading-[20.7px] text-white whitespace-nowrap">
              {frondData?.frond?.title ? frondData?.frond?.title : "-"}
            </span>
          </div>
        </div>
      </div>
      <div className="w-full h-[231px] relative">
        <MapContainer
          className="frond-details-map"
          center={position}
          zoom={12}
          dragging={false}
          doubleClickZoom={false}
          zoomControl={false}
          scrollWheelZoom={false}
          style={{
            height: "231px",
            width: "100%",
            position: "relative",
            zIndex: "20",
          }}
          whenCreated={(map) => {
            map.on("load", handleMapLoad);
          }}
        >
          <TileLayer
            eventHandlers={{
              tileload: handleTileLoad,
            }}
            attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=bGzRVT1WzXv8lPpb0ecTZTmZC0ZK2AIyJA2YMyPE5Dp1FNFqlX4sVyI6PSbA0iMH"
          />
          <CustomMarker />
        </MapContainer>
        {!isPageLoaded && (
          <div
            className={`bg-gray-400 rounded-t-[20px] absolute inset-0 transition-all duration-300 flex justify-center items-center ${
              isPageLoaded ? "opacity-0 z-10" : "opacity-100 z-40"
            }`}
          >
            <span className="flex gap-2 justify-center items-center text-white">
              <i className="fa-solid fa-spinner fa-spin text-[30px]"></i>
            </span>
          </div>
        )}
        {isShowMap && isPageLoaded ? (
          <div
            className={`bg-gray-400 absolute inset-0 transition-all duration-300 rounded-t-[20px] ${
              isShowMap && isPageLoaded ? "opacity-100 z-40" : "opacity-0 z-10"
            }`}
          >
            <Image
              classNames={{
                wrapper: "w-full h-full min-w-full",
                img: "temp-map-details rounded-[20px] object-cover",
              }}
              src={tempMap}
              alt="Map"
              className="size-full"
            />
          </div>
        ) : null}
      </div>
      <div className="flex justify-between items-start py-2.5 px-5 min-h-[72px]">
        <div className="flex flex-col gap-2">
          <h5 className="text-base text-textColor font-normal leading-[18.4px]">
            {frondData?.frond?.country
              ? `${frondData?.frond?.country}, `
              : `Country, `}
            {frondData?.frond?.city ? frondData?.frond?.city : "City"}
          </h5>
          <p className="text-sm text-cardGrayColor">
            {frondData?.frond?.location
              ? frondData?.frond?.location
              : "Location"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ShareHeader;
