import React, { useContext, useEffect, useState } from "react";
import magnifier from "../../Assets/my-fronds/magnifier.svg";
import notification from "../../Assets/my-fronds/notification.svg";
import closeNotificationsIcon from "../../Assets/my-fronds/cross-square.svg";
import arrowDown from "../../Assets/my-fronds/down.svg";
import userIcon from "../../Assets/dropdown/navbar/user.svg";
import moonIcon from "../../Assets/dropdown/navbar/moon.svg";
import sunIcon from "../../Assets/dropdown/navbar/sun.svg";
import moonIcon2 from "../../Assets/dropdown/navbar/moonIcon2.svg";
import sunIcon2 from "../../Assets/dropdown/navbar/sunIcon2.svg";
import exitIcon from "../../Assets/dropdown/navbar/exit.svg";
import userImg from "../../Assets/notifications/user-img.svg";
import dotsIcon from "../../Assets/notifications/dots-vertical.svg";
import commentIcon from "../../Assets/notifications/comment-icon.svg";
import likeIcon from "../../Assets/notifications/like-icon.svg";
import inviteIcon from "../../Assets/notifications/invite-icon.svg";
import taskIcon from "../../Assets/notifications/task-icon.svg";

// import { Nav, Navbar, Container } from "react-bootstrap";
import { connect } from "react-redux";

// import { logout } from "../../store/actions/auth";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Button,
  Image,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  DropdownTrigger,
  Input,
  linkAnchorClasses,
} from "@nextui-org/react";
// import {
//   SignIn,
//   SignInButton,
//   SignUpButton,
//   SignedIn,
//   SignedOut,
//   UserButton,
// } from "@clerk/clerk-react";
import Logo from "../../Assets/Images/logo.png";
// import {
//   Modal,
//   ModalContent,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   useDisclosure,
// } from "@nextui-org/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useClerk, useUser } from "@clerk/clerk-react";
import { AuthContext } from "Context/AuthContext";
import useSize from "hooks/useSize";
import { SidebarContext } from "Context/SidebarContext";
import GlobalSearch from "components/GlobalSearch/GlobalSearch";
import axios from "axios";
import { toast } from "react-toastify";
import EchoClient from "Echo/echo";

const ProfileNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const { token, setToken } = useContext(AuthContext);
  const isSignedIn = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = event => {
      if (event.key === "token") {
        if (!event.newValue) {
          setToken(null);
          navigate("/login");
          localStorage.removeItem("token");
          sessionStorage.clear();
        } else if (localStorage.getItem("token") !== token) {
          setToken(null);
          navigate("/login");
          localStorage.removeItem("token");
          sessionStorage.clear();
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const [theme, setTheme] = useState("light");

  const { session } = useClerk();
  const { signOut } = useClerk();
  const clerkUser = useUser();

  function logout() {
    if (session) {
      session?.end();
    }
    setToken(null);
    localStorage.removeItem("token");
    navigate("/login");
    // signOut({redirectUrl: '/login'});
  }

  const [userEmail, setUserEmail] = useState(null);
  const [userFullName, setUserFullName] = useState(null);
  const [userImg, setUserImg] = useState(null);

  const getData = () => {
    if (session?.user && isSignedIn) {
      setUserImg(clerkUser.user.imageUrl);
      setUserFullName(clerkUser.user.fullName);
      setUserEmail(clerkUser.user.primaryEmailAddress.emailAddress);
    }
  };

  useEffect(() => {
    getData();
  }, [clerkUser.user]);

  const { width } = useSize();
  const { isOpen } = useContext(SidebarContext);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        if (isOpen && width < 768) {
          setIsScrolled(false);
        } else {
          setIsScrolled(true);
        }
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Run the scroll handler immediately to update isScrolled
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen, width]);

  // Notification Functions

  const [targetId, setTargetId] = useState(null);
  const [itemKey, setItemKey] = useState(null);

  useEffect(() => {
    if (itemKey === "Remove Notification") {
      setItemKey(null);
    } else if (itemKey === "Report Issue") {
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id) => {
    setItemKey(key);
    setTargetId(id);
  };

  const handleDotsClick = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  const handleResize = () => {
    const scrollOutter = document.querySelector(".scroll-outter");
    const scrollInner = document.querySelector(".scroll-inner");

    if (scrollOutter && scrollInner) {
      const scrollOutterWidth = scrollOutter.offsetWidth;
      const scrollInnerWidth = scrollInner.offsetWidth;
      if (width >= 1024) {
        if (notificationsItems?.length > 4) {
          scrollInner.style.width = `calc(${scrollOutterWidth + 20}px)`;
        } else {
          scrollInner.style.width = `calc(${scrollOutterWidth + 10}px)`;
        }
      } else {
        scrollInner.style.width = `calc(${scrollOutterWidth + 8}px)`;
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleResize();
    }, 500);
  }, [isDropdownOpen]);

  // Global Search

  const [isSearchOpen, setIsSearchOpen] = useState(false);

  // Notifications Integration

  const timeAgo = dateString => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  const [userId, setUserId] = useState(null);

  const getUserData = async () => {
    try {
      const res = await axios.get("/user", {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      });
      setUserId(res.data?.id);
    } catch (error) {
      toast("Failed to fetch user data", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const authToken = localStorage.getItem("token");

  const [notificationsItems, setNotificationsItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getNotifications = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/notifications`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      setNotificationsItems(data?.notifications);

      EchoClient.channel(`user.notifications.${userId}`).listen(
        "NotificationSent",
        event => {
          console.log(event);
        },
      );
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  const readNotification = async id => {
    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/notifications/${id}/read`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        },
      );

      getNotifications();
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    if (userId) {
      getNotifications();
    }
  }, [userId]);

  function openNotification(id, type, link) {
    readNotification(id);
    if (type === "task") {
      navigate(`/frond/tasklist/all/${link}`);
    } else if (type === "like" || type === "comment") {
      navigate(`/community`);
    } else if (type === "invite") {
      navigate(`/invitations`);
    }
  }

  return (
    <>
      <Navbar
        isBordered
        isMenuOpen={isMenuOpen}
        onMenuOpenChange={setIsMenuOpen}
        maxWidth="full"
        style={{ height: "69px", zIndex: "9999" }}
        className={`shadow-navShadow ${
          isScrolled ? "bg-[rgba(255,255,255,.9)]" : "bg-[#fff]"
        } 2xl:px-[120px] xl:px-[60px] sm:px-[30px] px-2.5 profile-nav border-none`}
      >
        <NavbarContent className="flex gap-9" justify="start w-fit">
          <NavbarBrand style={{ maxWidth: "98px", maxHeight: "28px" }}>
            <Link to={"/"}>
              <Image src={Logo} style={{ width: "98px", maxHeight: "28px" }} />
            </Link>
          </NavbarBrand>
          <NavbarItem className="search-navbar hidden sm:flex">
            <button
              onClick={() => {
                setIsSearchOpen(true);
              }}
              className="px-15px h-[39px] w-[284px] bg-[#F5F6FF] rounded-[20px] flex items-center gap-2.5"
            >
              <Image
                src={magnifier}
                alt="magnifier"
                className="min-w-[18px] min-h-[18px]"
              />
              <span className="text-[#424C9BB2] opacity-70">
                Search frond...
              </span>
            </button>
          </NavbarItem>
        </NavbarContent>

        <NavbarContent justify="end w-fit" className="flex gap-9">
          <NavbarItem className="w-[24px] h-[24px]">
            <Dropdown
              closeOnSelect={false}
              isOpen={isDropdownOpen}
              onOpenChange={open => setIsDropdownOpen(open)}
              classNames={{
                content:
                  "sm:min-w-[380px] sm:max-w-[380px] min-w-[300px] max-w-[300px] p-0 rounded-[20px]",
                base: "p-0",
              }}
              placement="bottom-end"
            >
              <DropdownTrigger>
                <button className="w-[24px] h-[24px]">
                  <Image src={notification} alt="notification" />
                </button>
              </DropdownTrigger>
              <DropdownMenu
                classNames={{
                  base: "p-0 scroll-outter overflow-hidden rounded-[20px]",
                  list: "overflow-x-hidden overflow-y-auto max-h-[400px] rounded-[20px] gap-0 relative scroll-inner",
                }}
                aria-label="Static Actions"
              >
                <DropdownItem
                  isReadOnly
                  className="min-h-[56px] max-h-[56px] z-[99] bg-white fixed rounded-tl-[20px] rounded-tr-[20px] rounded-br-none rounded-bl-none top-0 left-0 right-0 p-0"
                >
                  <div className="flex justify-between items-center px-5">
                    <div className="flex items-center gap-2">
                      <Image
                        src={notification}
                        className="w-[24px] h-[24px]"
                        alt="notification"
                      />
                      <h4 className="text-blueTimeline font-bold text-lg">
                        Notifications
                      </h4>
                    </div>
                    <button onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                      <Image
                        src={closeNotificationsIcon}
                        alt="Close Notifications"
                        className="w-[24px] h-[24px]"
                      />
                    </button>
                  </div>
                </DropdownItem>
                {notificationsItems?.length > 0 ? (
                  notificationsItems.map((item, idx) => (
                    <DropdownItem
                      onClick={() => {
                        setIsDropdownOpen(false);
                        openNotification(item?.id, item?.type, item?.link);
                      }}
                      key={item?.id}
                      className={`${
                        idx === 0 ? "pt-[56px] pb-0 px-0" : "p-0"
                      } notification-item`}
                    >
                      <div
                        className={`flex justify-between gap-2.5 px-5 py-2.5 ${
                          item?.is_read ? "bg-white" : "bg-lightWhite"
                        }`}
                      >
                        <div className="flex items-start gap-2">
                          <div className="relative">
                            <Image
                              src={item?.sender?.img_url}
                              alt={item?.sender?.name}
                              className="min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] rounded-full"
                            />
                            <div className="absolute bottom-0 right-0 min-w-5 min-h-5">
                              {item?.type === "task" && (
                                <Image
                                  src={taskIcon}
                                  className="w-5 h-5"
                                  alt="Task"
                                />
                              )}
                              {item?.type === "invite" && (
                                <Image
                                  src={inviteIcon}
                                  className="w-5 h-5"
                                  alt="Task"
                                />
                              )}
                              {item?.type === "comment" && (
                                <Image
                                  src={commentIcon}
                                  className="w-5 h-5"
                                  alt="Task"
                                />
                              )}
                              {item?.type === "like" && (
                                <Image
                                  src={likeIcon}
                                  className="w-5 h-5"
                                  alt="Task"
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col">
                            <p className="mb-1.5 text-cardGrayColor leading-[22px] font-normal text-sm break-words">
                              <span className="text-textColor font-bold">
                                {item?.sender?.name}{" "}
                              </span>
                              {item?.type === "task" &&
                                "assigned you a task in"}
                              {item?.type === "invite" && "Invited you to"}
                              {item?.type === "comment" && "commented on your"}
                              {item?.type === "like" && "liked your"}
                              <span className="text-textColor font-bold">
                                {" "}
                                {item?.title}
                              </span>
                            </p>
                            <p className="text-cardGrayColor text-xs leading-[13.8px]">
                              {timeAgo(item?.updated_at)}
                            </p>
                          </div>
                        </div>
                        <Dropdown
                          classNames={{
                            content:
                              "min-w-[164px] max-w-[164px] py-0 px-[5px] rounded-[10px]",
                          }}
                          placement="left-start"
                        >
                          <DropdownTrigger>
                            <button
                              className="w-[24px] h-[24px]"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <img
                                src={dotsIcon}
                                alt="Dots"
                                className="min-w-[24px] min-h-[24px]"
                              />
                            </button>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Notifications Actions"
                            onAction={key => openModal(key, 1)}
                          >
                            <DropdownItem
                              textValue="Remove Notification"
                              key="Remove Notification"
                              className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                            >
                              Remove Notification
                            </DropdownItem>
                            <DropdownItem
                              key="Report Issue"
                              className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                              color="danger"
                            >
                              Report Issue
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>
                    </DropdownItem>
                  ))
                ) : (
                  <DropdownItem
                    isReadOnly
                    className="pt-[56px] pb-0 px-5 pointer-events-none"
                  >
                    <span className="text-textColor font-medium text-lg flex justify-center items-center pb-2.5">
                      There are no notifications
                    </span>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
          <NavbarItem>
            <Dropdown
              classNames={{
                content: "min-w-[341px] max-w-[341px] p-5 rounded-[20px]",
              }}
              placement="bottom-end"
              closeOnSelect={true}
            >
              <DropdownTrigger>
                <button className="flex gap-1 items-center">
                  <Image
                    src={userImg}
                    alt="Profile Pic"
                    className="w-[34px] h-[34px] rounded-full"
                  />
                  <Image src={arrowDown} alt="Arrow" />
                </button>
              </DropdownTrigger>
              <DropdownMenu aria-label="User Account" variant="light">
                <DropdownItem
                  isReadOnly
                  key="user Info"
                  className="relative cursor-auto p-0 after:absolute after:left-0 after:right-0 after:bottom-[-20px] after:h-px after:bg-grayBorder mb-[35px]"
                >
                  <div className="flex gap-4 items-center">
                    <Image
                      src={userImg}
                      alt="Profile Pic"
                      className="min-w-[50px] min-h-[50px] max-w-[50px] max-h-[50px] rounded-full"
                    />
                    <div className="user-data">
                      <h4 className="text-base font-bold text-textColor mb-0.5">
                        {userFullName}
                      </h4>
                      <p className="text-cardGrayColor text-base font-normal break-before-all">
                        {userEmail}
                      </p>
                    </div>
                  </div>
                </DropdownItem>
                <DropdownItem
                  key="Profile"
                  className="relative p-0 after:absolute after:left-0 after:right-0 after:bottom-[-20px] after:h-px after:bg-grayBorder mb-[30px]"
                >
                  <Link
                    to={"/profile"}
                    className="flex items-center gap-2 transition-all duration-300 bg-white hover:bg-[#F5F6FF] rounded-[10px] py-2 px-2.5"
                  >
                    <Image src={userIcon} alt="User" />
                    <p className="text-base font-normal text-textColor">
                      My Profile
                    </p>
                  </Link>
                </DropdownItem>
                <DropdownItem
                  isReadOnly
                  key="Theme"
                  className={`relative p-0 after:absolute after:left-0 after:right-0 after:bottom-[-20px] after:h-px after:bg-grayBorder mb-[30px]`}
                >
                  <h6 className="text-cardGrayColor text-sm font-bold mb-2 cursor-auto">
                    Theme
                  </h6>

                  <div
                    className={`theme-box relative after:absolute after:bg-darkBlue after:w-1/2 ${
                      theme === "light" ? "after:left-0" : "after:left-[50%]"
                    } after:duration-300 after:transition-all after:top-0 after:bottom-0 after:rounded-[65px] after:z-10 flex items-center rounded-[65px] bg-[#F5F6FF]`}
                  >
                    <button
                      onClick={() => {
                        setTheme("light");
                      }}
                      className="w-1/2 relative z-20 py-1"
                    >
                      <div className="light flex  gap-2 py-1 justify-center items-center relative z-20">
                        <div className="relative w-[24px] h-[24px]">
                          <img
                            src={sunIcon}
                            alt="Sun"
                            className={`absolute inset-0 transition-all duration-200 ${
                              theme === "light" ? "opacity-100" : "opacity-0"
                            }`}
                          />
                          <img
                            src={sunIcon2}
                            className={`absolute inset-0 transition-all duration-200 ${
                              theme === "light" ? "opacity-0" : "opacity-100"
                            }`}
                            alt="Sun"
                          />
                        </div>
                        <span
                          className={`text-base font-normal transition-all duration-300 ${
                            theme === "light" ? "text-white" : "text-darkBlue"
                          }`}
                        >
                          Light
                        </span>
                      </div>
                    </button>
                    <button
                      onClick={() => {
                        setTheme("dark");
                      }}
                      className="w-1/2 relative z-20 py-1"
                    >
                      <div className="dark flex gap-2 py-1 justify-center items-center relative z-20">
                        <div className="relative w-[24px] h-[24px]">
                          <img
                            src={moonIcon}
                            alt="Moon"
                            className={`absolute inset-0 transition-all duration-200 ${
                              theme === "dark" ? "opacity-0" : "opacity-1000"
                            }`}
                          />
                          <img
                            src={moonIcon2}
                            className={`absolute inset-0 transition-all duration-200 ${
                              theme === "dark" ? "opacity-100" : "opacity-0"
                            }`}
                            alt="Moon"
                          />
                        </div>
                        <span
                          className={`text-base font-normal transition-all duration-300 ${
                            theme === "dark" ? "text-white" : "text-darkBlue"
                          }`}
                        >
                          Dark
                        </span>
                      </div>
                    </button>
                  </div>
                </DropdownItem>
                <DropdownItem isReadOnly key="Sign out" className="p-0">
                  <button
                    onClick={() => {
                      logout();
                    }}
                    className="flex items-center gap-3 py-2 px-2.5 w-full transition-all duration-300 bg-white hover:bg-[#F5F6FF] rounded-[10px] "
                  >
                    <Image src={exitIcon} alt="Exit" />
                    <h6 className="text-textColor font-normal text-base">
                      Log Out
                    </h6>
                  </button>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
        </NavbarContent>
      </Navbar>
      {isSearchOpen && (
        <GlobalSearch
          isOpen={isSearchOpen}
          onClose={() => {
            setIsSearchOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ProfileNavbar;
