import React from "react";
import { createRoot } from "react-dom/client";

// App
import { App } from "./App";

// Redux Store
import { Provider } from "react-redux";
import { store } from "./store/store";

// Main CSS
import "./styles/app.css";
import { ClerkProvider } from "@clerk/clerk-react";

import "./index.css";
import AuthContextProvider from "./Context/AuthContext";

import "@fortawesome/fontawesome-free/css/all.min.css";
import SidebarContextProvider from "Context/SidebarContext";
import Error500ContextProvider from "Context/Error500Context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "leaflet/dist/leaflet.css";
import PopUpContextProvider from "Context/PopUpContext";
import SearchFrondContextProvider from "Context/SearchFrondContext";
import ArchiveContextProvider from "Context/ArchiveContext";
import DeleteScrapbookImagesContextProvider from "Context/DeleteScrapbookImagesContext";
import DeleteProjectsContextProvider from "Context/DeleteProjectsContext";
import TasksBoardContextProvider from "Context/TasksBoardContext";
import ViewProjectsContextProvider from "Context/ViewProjectsContext";
import FilterContextProvider from "Context/FilterContext";
import SortContextProvider from "Context/SortContext";
import LoadingContextProvider from "Context/LoadingContext";
import { QueryClient, QueryClientProvider } from "react-query";
import DeleteScrapbookFilesContextProvider from "Context/DeleteScrapbookFilesContext";
import SettingsContextProvider from "Context/SettingsContext";
import HashtagsContextProvider from "Context/HashtagsContext";
import CashedDataContextProvider from "Context/CashedDataContext";

// const Root = document.getElementById("root");

const PUBLISHABLE_KEY =
  "pk_test_ZmlybS1zcGlkZXItNTEuY2xlcmsuYWNjb3VudHMuZGV2JA";
// process.env.REACT_APP_API_ENDPOINT_URL

if (!PUBLISHABLE_KEY) {
  throw new Error("hady");
}

const query = new QueryClient();

function Root() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={query}>
        <LoadingContextProvider>
        <AuthContextProvider>
          <Error500ContextProvider>
            <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
              <SettingsContextProvider>
                <CashedDataContextProvider>
                  <ViewProjectsContextProvider>
                    <SearchFrondContextProvider>
                      <SidebarContextProvider>
                        <PopUpContextProvider>
                          <ArchiveContextProvider>
                            <DeleteProjectsContextProvider>
                              <DeleteScrapbookImagesContextProvider>
                                <DeleteScrapbookFilesContextProvider>
                                  <TasksBoardContextProvider>
                                    <SortContextProvider>
                                      <FilterContextProvider>
                                        <HashtagsContextProvider>
                                          <App />
                                        </HashtagsContextProvider>
                                      </FilterContextProvider>
                                    </SortContextProvider>
                                  </TasksBoardContextProvider>
                                </DeleteScrapbookFilesContextProvider>
                              </DeleteScrapbookImagesContextProvider>
                            </DeleteProjectsContextProvider>
                          </ArchiveContextProvider>
                        </PopUpContextProvider>
                        <ToastContainer />
                      </SidebarContextProvider>
                    </SearchFrondContextProvider>
                  </ViewProjectsContextProvider>
                </CashedDataContextProvider>
              </SettingsContextProvider>
            </ClerkProvider>
          </Error500ContextProvider>
        </AuthContextProvider>
        </LoadingContextProvider>
      </QueryClientProvider>
    </Provider>
  );
}

createRoot(document.getElementById("root")).render(<Root />);
