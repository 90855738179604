import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { PopUpContext } from "Context/PopUpContext";
import useSettings from "hooks/useSettings";
import { SettingsContext } from "Context/SettingsContext";
import EditLocationModal from "./EditLocationModal";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import L from "leaflet";
import mapMarker from "../../Assets/create-frond/geolocation.svg";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import tempMap from "../../Assets/create-frond/temp-map.png";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Tab,
  Tabs,
} from "@nextui-org/react";
import editPen from "../../Assets/frond-details/edit-pen.svg";
import arrowLeft from "../../Assets/frond-details/arrow-left.svg";
import dots from "../../Assets/my-fronds/frond/dots.svg";
import ShareFrond from "components/MyFrondsFuctions/ShareFrond";
import LeaveProjectModal from "components/SharedFrondsFunctions/LeaveProjectsModal";
import DeleteModal from "components/MyFrondsFuctions/DeleteModal";
import ArchiveModal from "components/MyFrondsFuctions/ArchiveModal";

const DetailsTemplate = () => {
  const { frondId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [frondData, setFrondData] = useState([]);
  let authToken = localStorage.getItem("token");

  useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  const getFrondData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/frond/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data);
      if (data?.response?.data?.latitude && data?.response?.data?.longitude) {
        setPosition([
          data?.response?.data?.latitude,
          data?.response?.data?.longitude,
        ]);
        setIsShowMap(false);
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFrondData();
  }, []);

  const { isEditTitle, setIsEditTitle, setIsArchiveProject } =
    useContext(PopUpContext);

  useEffect(() => {
    if (isEditTitle) {
      getFrondData();
    }
  }, [isEditTitle]);

  useSettings({ projectId: frondId });

  // Map Changes

  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const [editLoactionModal, setEditLocationModal] = useState(false);
  const [isShowMap, setIsShowMap] = useState(true);
  const [position, setPosition] = useState([0, 0]);

  useEffect(() => {
    const handleLoad = () => {
      setIsPageLoaded(true);
    };

    if (document.readyState === "complete") {
      setTimeout(() => {
        handleLoad();
      }, 1000);
    } else {
      window.addEventListener("load", handleLoad);
      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  useEffect(() => {}, [position]);

  const [mapLoaded, setMapLoaded] = useState(false);

  const handleTileLoad = () => {
    setMapLoaded(true);
  };

  const handleMapLoad = () => {
    setMapLoaded(true);
  };

  const CustomMarker = () => {
    const map = useMap();
    map.setView(position, 12);

    const icon = L.divIcon({
      html: `<img src="${mapMarker}" style="width: 28px; height: 28px;" />`,
      className: "",
    });

    return <Marker position={position} icon={icon}></Marker>;
  };

  // Tabs

  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState(pathname);

  useEffect(() => {
    setActiveLink(pathname.split("/")[2]);
  }, [pathname, activeLink]);

  const links = [
    {
      title: "About",
      link: `/frond/about/${frondId}`,
    },
    {
      title: "Scrapbook",
      link: `/frond/scrapbook/images/${frondId}`,
    },
    {
      title: "People",
      link: `/frond/people/${frondId}`,
    },
    {
      title: "Tasklist",
      link: `/frond/tasklist/all/${frondId}`,
    },
    {
      title: "Updates",
      link: `/frond/updates/${frondId}`,
    },
    {
      title: "Discover",
      link: `/frond/discover/${frondId}`,
    },
    {
      title: "Settings",
      link: `/frond/settings/${frondId}`,
    },
  ];

  const navigate = useNavigate();

  if (!frondSettings.is_owner) {
    links.pop();
  }

  if (frondData?.frond?.category !== "Escape") {
    links.splice(5, 1);
  }

  // Frond Actions

  const openModal = (key) => {
    setItemKey(key);

    if (key === "delete") {
      setDeleteFrondModal(true);
    } else if (key === "archive") {
      setArchiveFrondModal(true);
    } else if (key === "leave") {
      setLeaveFrondModal(true);
    } else if (key === "share") {
      setShareFrondModal(true);
    } else if (key === "unArchive") {
      unArchiveFrond();
    }
  };

  const unArchiveFrond = async () => {
    let authToken = localStorage.getItem("token");
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/restore`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast("Project restored successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      setIsArchiveProject(true);
      setTimeout(() => {
        setIsArchiveProject(false);
      }, 1000);

      setIsEditTitle(true);

      setTimeout(() => {
        setIsEditTitle(false);
      }, 1000);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  const [itemKey, setItemKey] = useState(null);

  const [archiveFrondModal, setArchiveFrondModal] = useState(false);
  const [deleteFrondModal, setDeleteFrondModal] = useState(false);
  const [leaveFrondModal, setLeaveFrondModal] = useState(false);
  const [shareFrondModal, setShareFrondModal] = useState(false);

  useEffect(() => {}, [frondData]);

  return (
    <>
      <section className="frond-details rounded-4xl border-2 border-solid border-grayBorder p-5 bg-white no-focus">
        <div className="map min-w-full min-h-[303px] max-h-[303px] border-2 border-solid border-grayBorder rounded-[20px] relative no-focus">
          <div className="absolute bg-frondDetails rounded-[20px] h-[120px] w-full z-[999]">
            <div className="absolute top-5 phone:left-5 phone:right-5 left-2.5 right-2.5 flex justify-between items-center z-30 phone:gap-[30px] gap-2.5">
              <div className="flex phone:gap-2.5 gap-[5px] items-center overflow-hidden">
                <button
                  onClick={() => navigate(-1)}
                  className="phone:min-w-[24px] phone:min-h-[24px] min-w-[12px] min-h-[12px]"
                >
                  <Image
                    src={arrowLeft}
                    alt="Arrow"
                    className="phone:min-w-[24px] phone:min-h-[24px] min-w-[12px] min-h-[12px]"
                  />
                </button>
                <span className="phone:text-lg text-[12px] font-bold leading-[20.7px] text-white whitespace-nowrap">
                  {frondData?.frond?.title ? frondData?.frond?.title : "-"}
                </span>
              </div>
              <div className="flex gap-2.5 items-center min-w-fit">
                <Dropdown
                  classNames={{
                    content: `${
                      !frondData?.is_archived
                        ? "min-w-[88px] max-w-[88px]"
                        : "min-w-[92px] max-w-[92px]"
                    } py-0 px-[5px] rounded-[10px]`,
                  }}
                  placement="left-start"
                >
                  <DropdownTrigger>
                    <button className="w-[24px] h-[24px] relative z-40">
                      <img
                        src={dots}
                        alt="Dots"
                        className="phone:min-w-[24px] phone:max-w-[24px] phone:min-h-[24px] phone:max-h-[24px] min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px]"
                      />
                    </button>
                  </DropdownTrigger>
                  <DropdownMenu
                    aria-label="Frond Actions"
                    onAction={(key) => {
                      openModal(key);
                    }}
                  >
                    {!frondData?.is_shared && (
                      <DropdownItem
                        textValue="edit frond"
                        key="share"
                        className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                      >
                        Share
                      </DropdownItem>
                    )}
                    {!frondData?.is_archived && !frondData?.is_shared && (
                      <DropdownItem
                        textValue="edit frond"
                        key="archive"
                        className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                      >
                        Archive
                      </DropdownItem>
                    )}
                    {frondData?.is_archived && frondSettings.is_owner && (
                      <DropdownItem
                        textValue="edit frond"
                        key="unArchive"
                        className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                      >
                        Unarchive
                      </DropdownItem>
                    )}
                    {frondData?.is_shared && (
                      <DropdownItem
                        textValue="edit frond"
                        key="leave"
                        className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                        color="danger"
                      >
                        Leave
                      </DropdownItem>
                    )}
                    {!frondData?.is_shared && (
                      <DropdownItem
                        key="delete"
                        className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                        color="danger"
                      >
                        Delete
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="w-full h-[231px] relative">
            {isPageLoaded && (
              <MapContainer
                className="frond-details-map"
                center={position}
                zoom={12}
                dragging={false}
                doubleClickZoom={false}
                zoomControl={false}
                scrollWheelZoom={false}
                style={{
                  height: "231px",
                  width: "100%",
                  position: "relative",
                  zIndex: "20",
                }}
                whenCreated={(map) => {
                  map.on("load", handleMapLoad);
                }}
              >
                <TileLayer
                  eventHandlers={{
                    tileload: handleTileLoad,
                  }}
                  attribution='<a href="https://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=bGzRVT1WzXv8lPpb0ecTZTmZC0ZK2AIyJA2YMyPE5Dp1FNFqlX4sVyI6PSbA0iMH"
                />
                <CustomMarker />
              </MapContainer>
            )}
            {!isPageLoaded && (
              <div
                className={`bg-gray-400 rounded-t-[20px] absolute inset-0 transition-all duration-300 flex justify-center items-center ${
                  isPageLoaded ? "opacity-0 z-10" : "opacity-100 z-40"
                }`}
              >
                <span className="flex gap-2 justify-center items-center text-white">
                  <i className="fa-solid fa-spinner fa-spin text-[30px]"></i>
                </span>
              </div>
            )}
            {isShowMap && isPageLoaded ? (
              <div
                className={`bg-gray-400 absolute inset-0 transition-all duration-300 rounded-t-[20px] ${
                  isShowMap && isPageLoaded
                    ? "opacity-100 z-40"
                    : "opacity-0 z-10"
                }`}
              >
                <Image
                  classNames={{
                    wrapper: "w-full h-full min-w-full",
                    img: "temp-map-details rounded-[20px] object-cover",
                  }}
                  src={tempMap}
                  alt="Map"
                  className="size-full"
                />
              </div>
            ) : null}
          </div>
          <div className="flex justify-between items-start py-2.5 px-5 min-h-[72px]">
            <div className="flex flex-col gap-2">
              <h5 className="text-base text-textColor font-normal leading-[18.4px]">
                {frondData?.frond?.country
                  ? `${frondData?.frond?.country}, `
                  : `Country, `}
                {frondData?.frond?.city ? frondData?.frond?.city : "City"}
              </h5>
              <p className="text-sm text-cardGrayColor">
                {frondData?.frond?.location
                  ? frondData?.frond?.location
                  : "Location"}
              </p>
            </div>
            {frondSettings.is_owner && (
              <button onClick={() => setEditLocationModal(true)}>
                <Image src={editPen} alt="Pen" className="w-6 h-6" />
              </button>
            )}
          </div>
        </div>
        <div className="frond-actions relative flex justify-evenly items-center after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[2px] after:bg-grayBorder after:z-[10] no-focus mb-5">
          <Tabs
            selectedKey={activeLink}
            variant="underlined"
            aria-label="Tabs"
            classNames={{
              base: "w-full",
              tabList: "w-full justify-evenly relative z-20",
              tab: "w-fit",
              tabContent:
                "text-textColor font-normal transition-all duration-100 phone:text-base text-xs leading-[18.4px] group-data-[selected=true]:font-bold group-data-[selected=true]:text-transparent group-data-[selected=true]:bg-mainGradiant group-data-[selected=true]:bg-clip-text",
              cursor:
                "bg-mainGradiant z-20 bottom-[-4px] rounded-[6px] h-[2px] w-full",
            }}
          >
            {links.map((item) => (
              <Tab
                key={item.title.toLowerCase()}
                title={item.title}
                as={Link}
                to={item.link}
              />

              // <Link
              //   to={item.link}
              //   key={idx}
              //   ref={(el) => (linkRefs.current[idx] = el)}
              //   className={`flex justify-evenly items-center p-3 ${
              //     activeLink === item.link ? "text-mainGradiant" : ""
              //   }`}
              // >
              //   <span
              //     className={`${
              //       activeLink === item.link
              //         ? "bg-clip-text text-transparent bg-mainGradiant font-bold"
              //         : "text-cardGrayColor font-normal"
              //     } transition-all duration-100 text-base leading-[18.4px]`}
              //   >
              //     {item.title}
              //   </span>
              // </Link>
            ))}
          </Tabs>
        </div>
        <Outlet />
      </section>
      {editLoactionModal && (
        <EditLocationModal
          isOpen={editLoactionModal}
          onClose={() => {
            setEditLocationModal(false);
          }}
          getFrondData={getFrondData}
          isModalOpen={true}
          frondData={{
            editId: frondData?.frond?.id,
            editLatitude: frondData?.latitude,
            editLongitude: frondData?.longitude,
            editCountry: frondData?.frond?.country,
            editCity: frondData?.frond?.city,
            editLocation: frondData?.frond?.location,
          }}
        />
      )}
      {itemKey === "archive" && (
        <ArchiveModal
          isOpen={archiveFrondModal}
          onClose={() => {
            setArchiveFrondModal(false);
          }}
          frondId={frondId}
          flag={true}
        />
      )}
      {itemKey === "delete" && (
        <DeleteModal
          isOpen={deleteFrondModal}
          onClose={() => {
            setDeleteFrondModal(false);
          }}
          flag={true}
          frondId={frondId}
        />
      )}
      {itemKey === "leave" && (
        <LeaveProjectModal
          isOpen={leaveFrondModal}
          onClose={() => {
            setLeaveFrondModal(false);
          }}
          frondId={frondId}
          flag={true}
        />
      )}
      {itemKey === "share" && (
        <ShareFrond
          isOpen={shareFrondModal}
          onClose={() => {
            setShareFrondModal(false);
          }}
          isModalOpen={shareFrondModal}
          frondId={frondId}
        />
      )}
    </>
  );
};

export default DetailsTemplate;
