import React, { useEffect, useState } from "react";
import calendarIcon from "../../../Assets/frond-details/discover/calendar.svg";
import geolocationIcon from "../../../Assets/frond-details/discover/geolocation.svg";
import categoryIcon from "../../../Assets/frond-details/discover/category.svg";
import plusIcon from "../../../Assets/frond-details/discover/plus-circle.svg";
import userIcon from "../../../Assets/frond-details/discover/profile-user.svg";
import messageEditIcon from "../../../Assets/frond-details/discover/message-edit.svg";
import { DateRangePicker, Image } from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import calendar from "../../../Assets/create-frond/calendar.svg";
import arrowRight from "../../../Assets/discover/arrow-right.svg";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import DiscoverModal from "./DiscoverModal";

export default function Discover({ flag }) {
  const [isLoading, setIsLoading] = useState(false);
  const [frondData, setFrondData] = useState([]);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [startDateArr, setStartDateArr] = useState("");
  const [endDateArr, setEndDateArr] = useState("");

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      dateRange: "",
      location: "",
      category: "",
      recommendations: [],
      guests: "",
    },
  });

  const { frondId } = useParams();
  let authToken = localStorage.getItem("token");

  const getFrondData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/frond/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data);

      if (data?.response?.data?.frond?.start_date) {
        setStartDateArr(data?.response?.data?.frond?.start_date.split("/"));
      }
      if (data?.response?.data?.frond?.end_date) {
        setEndDateArr(data?.response?.data?.frond?.end_date.split("/"));
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getFrondData();
  }, []);

  const [aiData, setAiData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  async function discoverData() {
    setIsModalOpen(true);
    setIsLoading(true);
    setAiData([]);

    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/discover/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setAiData(data);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }

    setIsLoading(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="discover relative min-h-[430px]">
      <form
        className={`transition-all duration-300 ${
          isModalOpen
            ? "opacity-0 z-10 pointer-events-none"
            : "opacity-100 z-20"
        } flex flex-col absolute left-5 right-0`}
        onSubmit={handleSubmit(discoverData)}
      >
        <div className="item flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder pb-4 mb-4">
          <div className="flex items-center gap-[13px]">
            <Image
              src={calendarIcon}
              alt="calendar"
              className="w-[24px] h-[24px]"
            />
            <div>
              <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
                Date
              </h4>

              <p className="text-base font-bold text-textColor leading-[22px]">
                {startDateArr
                  ? `${months[startDateArr[1] - 1]} ${startDateArr[0]}`
                  : null}
                {startDateArr && endDateArr && <span className="mx-1">-</span>}
                {endDateArr
                  ? `${months[endDateArr[1] - 1]} ${endDateArr[0]}`
                  : null}
              </p>
            </div>
          </div>
          {/* {editDate ? (
          <button className="leading-[18.4px] text-white w-[82px] h-[30px] rounded-4xl bg-mainGradiant py-1.5 px-2.5 flex justify-center items-center">
            Save
          </button>
        ) : (
          <button
            onClick={() => {
              setEditDate(true);
            }}
            type="button"
            className="flex items-center gap-2.5"
          >
            <Image
              src={messageEditIcon}
              alt="Edit Item"
              className="w-[24px] h-[24px]"
            />
            <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
              Edit
            </span>
          </button>
        )} */}
        </div>
        <div className="item flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder pb-4 mb-4">
          <div className="flex items-center gap-[13px]">
            <Image
              src={geolocationIcon}
              alt="geolocation"
              className="w-[24px] h-[24px]"
            />
            <div>
              <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
                Location
              </h4>
              <p className="text-base font-bold text-textColor leading-[22px]">
                {frondData?.frond?.location || "-"}
              </p>
            </div>
          </div>
          {/* <button type="button" className="flex items-center gap-2.5">
          <Image
            src={messageEditIcon}
            alt="Edit Item"
            className="w-[24px] h-[24px]"
          />
          <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
            Edit
          </span>
        </button> */}
        </div>
        <div className="item flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder pb-4 mb-4">
          <div className="flex items-center gap-[13px]">
            <Image
              src={categoryIcon}
              alt="category"
              className="w-[24px] h-[24px]"
            />
            <div>
              <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
                Category
              </h4>
              <p className="text-base font-bold text-textColor leading-[22px]">
                {frondData?.frond?.category}
              </p>
            </div>
          </div>
          {/* <button type="button" className="flex items-center gap-2.5">
          <Image
            src={messageEditIcon}
            alt="Edit Item"
            className="w-[24px] h-[24px]"
          />
          <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
            Edit
          </span>
        </button> */}
        </div>
        <div className="item flex justify-between items-center gap-2.5 border-b-1 border-solid border-grayBorder pb-4 mb-4">
          <div className="flex items-center gap-[13px]">
            <Image src={plusIcon} alt="plus" className="w-[24px] h-[24px]" />
            <div>
              <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
                Recommendations
              </h4>
              <p>
                {frondData?.tags?.length > 0
                  ? frondData?.tags.map((item, idx) => (
                      <>
                        {idx !== 0 && (
                          <span className="text-base font-bold text-textColor leading-[22px]">
                            ,{" "}
                          </span>
                        )}
                        <span
                          key={idx}
                          className="text-base font-bold text-textColor leading-[22px]"
                        >
                          {item.name.en}
                        </span>
                      </>
                    ))
                  : "-"}
              </p>
            </div>
          </div>
          {/* <button type="button" className="flex items-center gap-2.5">
          <Image
            src={messageEditIcon}
            alt="Edit Item"
            className="w-[24px] h-[24px]"
          />
          <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
            Edit
          </span>
        </button> */}
        </div>
        <div className="item flex justify-between items-center gap-2.5 mb-[40px]">
          <div className="flex items-center gap-[13px]">
            <Image src={userIcon} alt="guests" className="w-[24px] h-[24px]" />
            <div>
              <h4 className="mb-[5px] text-sm text-cardGrayColor leading-[16.1px]">
                Guests
              </h4>
              <p className="text-base font-bold text-textColor leading-[22px]">
                {frondData?.members?.length} Guests
              </p>
            </div>
          </div>
          {/* <button type="button" className="flex items-center gap-2.5">
          <Image
            src={messageEditIcon}
            alt="Edit Item"
            className="w-[24px] h-[24px]"
          />
          <span className="text-transparent bg-clip-text bg-mainGradiant leading-[18.4px]">
            Edit
          </span>
        </button> */}
        </div>
        <div className="flex justify-center items-center w-full">
          {flag ? (
            <Link
              to={"/discover/suggestions"}
              className={`w-[142px] h-[48px] py-3 px-5 rounded-4xl gap-2.5 bg-mainGradiant flex justify-center items-center text-white leading-[18.4px] font-bold`}
            >
              <span>Discover</span>
              <Image
                src={arrowRight}
                className="min-w-[24px] min-h-[24px]"
                alt="Arrow"
              />
            </Link>
          ) : (
            <button
              type="submit"
              className={`w-[118px] h-[39px] rounded-[27px] py-[11px] px-[25px] bg-mainGradiant flex justify-center items-center text-white leading-[18.4px] font-bold`}
            >
              {isLoading ? (
                <span className="flex justify-center items-center text-white">
                  <i className="fa-solid fa-spinner fa-spin"></i>
                </span>
              ) : (
                "Discover"
              )}
            </button>
          )}
        </div>
      </form>
      <div
        className={`transition-all duration-300 px-5 mb-5 ${
          isModalOpen ? "opacity-100 z-20" : "opacity-0 z-10"
        }`}
      >
        <>
          {isModalOpen && <DiscoverModal discoverData={aiData} />}
          <div className="w-full flex justify-center items-center mt-5">
            <button
              disabled={isLoading}
              onClick={() => {
                setIsModalOpen(false);
              }}
              className={`w-[148px] h-[39px] rounded-[27px] py-[11px] px-[25px] bg-mainGradiant flex justify-center items-center text-white leading-[18.4px] font-bold`}
            >
              End Planing
            </button>
          </div>
        </>
      </div>
    </section>
  );
}
