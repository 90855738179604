import React, { useContext, useEffect, useState } from "react";
import linkImg from "../../../Assets/landing/feedbackCardImg.svg";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
} from "@nextui-org/react";
import dots from "../../../Assets/scrapbook/dots-vertical.svg";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { PopUpContext } from "Context/PopUpContext";
import { toast } from "react-toastify";
import AddLink from "./PopUp/AddLink";
import useSettings from "hooks/useSettings";
import { SettingsContext } from "Context/SettingsContext";

// Helper function to clean the URL
const cleanUrl = (url) => {
  let cleanedUrl = url.includes("https")
    ? url.slice(8)
    : url.includes("http")
    ? url.slice(7)
    : url;
  cleanedUrl = cleanedUrl.includes("/") ? cleanedUrl.split("/")[0] : cleanedUrl;
  return cleanedUrl;
};

const ScrapbookLinks = () => {
  const [targetId, setTargetId] = useState(null);
  const [itemKey, setItemKey] = useState(null);
  const [editLinkModal, setEditLinkModal] = useState(false);
  const [frondData, setFrondData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [favicons, setFavicons] = useState({}); // Store favicons for URLs

  const { frondId } = useParams();
  const authToken = localStorage.getItem("token");

  const { isAddLink } = useContext(PopUpContext);

  // Function to calculate time ago
  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  // Fetch scrapbook links
  const getScrapbookLinks = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/scrapbook-links/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data || []);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getScrapbookLinks();
  }, [frondId]);

  useEffect(() => {
    if (isAddLink) {
      getScrapbookLinks();
    }
  }, [isAddLink]);

  // Fetch favicon for a URL and update the state
  const getFavicon = async (url) => {
    const cleanedUrl = cleanUrl(url);
    try {
      const {
        data: { icons },
      } = await axios.get(`https://favicongrabber.com/api/grab/${cleanedUrl}`);
      if (icons[0]?.src) {
        setFavicons((prevFavicons) => ({
          ...prevFavicons,
          [url]: icons[0].src,
        }));
      } else {
        throw new Error("No favicon found");
      }
    } catch (error) {
      // Fallback to Google S2 favicon if favicongrabber fails
      setFavicons((prevFavicons) => ({
        ...prevFavicons,
        [url]: `https://www.google.com/s2/favicons?domain=${cleanedUrl}`,
      }));
    }
  };

  // Trigger fetching favicons when frondData is updated
  useEffect(() => {
    frondData.forEach((item) => {
      if (item?.url && !favicons[item.url]) {
        getFavicon(item.url);
      }
    });
  }, [frondData]);

  // Delete a link
  const deleteRecord = async () => {
    if (!targetId) return;
    try {
      const { data } = await axios.delete(
        `https://frond-admin.code-minds.tech/api/scrapbook-links/${frondId}/${targetId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      getScrapbookLinks();
      toast("Link deleted successfully", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  };

  useEffect(() => {
    if (itemKey === "delete") {
      deleteRecord();
      setItemKey(null);
    } else if (itemKey === "edit") {
      setEditLinkModal(true);
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id) => {
    setItemKey(key);
    setTargetId(id);
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const { loadSettings } = useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  const [showLinks, setShowLinks] = useState(false);

  useEffect(() => {
    if (!loadSettings) {
      if (frondSettings.can_edit_links && !frondSettings.is_owner) {
        setShowLinks(true);
      } else if (frondSettings.is_owner) {
        setShowLinks(true);
      } else {
        setShowLinks(false);
      }
    }
  }, [loadSettings, frondSettings]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Pagination

  const ITEMS_PER_PAGE = 9;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (currentPage > Math.ceil(frondData.length / ITEMS_PER_PAGE)) {
      setCurrentPage(Math.max(Math.ceil(frondData.length / ITEMS_PER_PAGE), 1));
    }
  }, [frondData, currentPage]);

  const totalPages = Math.ceil(frondData.length / ITEMS_PER_PAGE);
  const currentItems = frondData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <>
      <section className="scrapbook-links">
        {frondData.length > 0 && (
          <div className="flex flex-col gap-[15px]">
            {currentItems.map((item, idx) => (
              <div
                key={item.id}
                className={`flex justify-between w-full ${
                  idx !== currentItems.length - 1 &&
                  "pb-[15px] border-b-1 border-solid border-b-grayBorder"
                }`}
              >
                <div className="flex items-start gap-2.5">
                  <Image
                    src={item?.user?.img_url}
                    className="phone:min-w-[45px] phone:min-h-[45px] max-w-[30px] max-h-[30px] rounded-full"
                    alt="Link Image"
                  />
                  <div>
                    <h4 className="text-base text-textColor font-bold leading-[22px] mb-[3px]">
                      {item.user?.name || "-"}
                    </h4>
                    <p className="text-sm text-cardGrayColor mb-2.5">
                      {timeAgo(item.created_at) || "-"}
                    </p>
                    <p className="text-mainBlue leading-[22px] font-bold mb-[8px]">
                      {item.title || "-"}
                    </p>
                    <div className="flex items-center gap-2.5">
                      <div className="bg-[#1976D21A] min-w-[44px] min-h-[44px] rounded-[6px] flex justify-center items-center">
                        <Image
                          src={favicons[item?.url] || "default-favicon-url.png"}
                          alt={item?.title}
                          className="min-w-[32px] min-h-[32px] max-w-[32px] max-h-[32px]"
                        />
                      </div>
                      <Link
                        target="_blank"
                        to={item?.url}
                        className="text-mainBlue underline underline-offset-4 leading-[22px] break-before-all"
                      >
                        {item.url || "-"}
                      </Link>
                    </div>
                  </div>
                </div>
                {showLinks ? (
                  <Dropdown
                    classNames={{
                      content:
                        "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                    }}
                    placement="left-start"
                  >
                    <DropdownTrigger>
                      <button
                        className="w-[24px] h-[24px]"
                        onClick={handleDotsClick}
                      >
                        <img
                          src={dots}
                          alt="Dots"
                          className="min-w-[24px] min-h-[24px]"
                        />
                      </button>
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Frond Actions"
                      onAction={(key) => openModal(key, item.id)}
                    >
                      <DropdownItem
                        textValue="edit Link"
                        key="edit"
                        className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        key="delete"
                        className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                        color="danger"
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : null}
              </div>
            ))}
          </div>
        )}
        {frondData?.length > 9 && (
          <div className="flex justify-center items-center gap-2.5 mt-6">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="smallPhone:px-4 px-2.5 py-2 smallPhone:text-base text-xs bg-mainGradiant rounded-xl text-white font-medium disabled:opacity-50"
            >
              Prev
            </button>
            <span className="smallPhone:text-lg text-sm text-textColor font-semibold">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="smallPhone:px-4 px-2.5 py-2 smallPhone:text-base text-xs bg-mainGradiant rounded-xl text-white font-medium disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}
        {editLinkModal && (
          <AddLink
            isOpen={editLinkModal}
            onClose={() => setEditLinkModal(false)}
            frondId={frondId}
            isModalOpen={true}
            linkId={targetId}
          />
        )}
      </section>
    </>
  );
};

export default ScrapbookLinks;
