import React, { useEffect, useState } from "react";
import rocket from "../../Assets/frond-details/rocket.svg";
import dollar from "../../Assets/frond-details/dollar.svg";
import calendar from "../../Assets/frond-details/calendar.svg";
import like from "../../Assets/frond-details/like.svg";
import { Image } from "@nextui-org/react";
import placholderIcon from "../../Assets/frond-details/placholder-icon.svg";

const ShareBody = ({ frondData }) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [startDateArr, setStartDateArr] = useState("");
  const [endDateArr, setEndDateArr] = useState("");

  useEffect(() => {
    if (frondData?.frond?.start_date) {
      setStartDateArr(frondData?.frond?.start_date.split("/"));
    }
    if (frondData?.frond?.end_date) {
      setEndDateArr(frondData.frond?.end_date.split("/"));
    }
  }, [frondData]);

  return (
    <>
      <section className="about-frond">
        <div className="flex gap-x-2.5 gap-y-2.5 items-start justify-between timelineMedia:flex-nowrap flex-wrap">
          <div className="content flex flex-col">
            <div className="flex items-start gap-5">
              <div className="info flex items-start phone:gap-[45px] gap-5 mb-5">
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Category
                  </h5>
                  <div className="flex items-center gap-[5px]">
                    <Image
                      src={rocket}
                      alt="Rocket"
                      className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                    />
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondData?.frond?.category}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Importance
                  </h5>
                  <div
                    className={`${
                      !frondData?.frond?.priority && "text-center text-[10px]"
                    }`}
                  >
                    <span
                      className={`leading-normal ${
                        frondData?.frond?.priority === "High" &&
                        "text-[#FF3D00E5]"
                      } ${
                        frondData?.frond?.priority === "Medium" &&
                        "text-[#FFC000]"
                      } ${
                        frondData?.frond?.priority === "Low" && "text-[#5CCDB0]"
                      } ${!frondData?.frond?.priority && "text-textColor"}`}
                    >
                      {frondData?.frond?.priority
                        ? frondData?.frond?.priority
                        : "-"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col gap-2">
                  <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Budget
                  </h5>
                  <div className="flex items-center gap-[5px]">
                    <Image
                      src={dollar}
                      alt="Dollar"
                      className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                    />
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondData?.frond?.budget
                        ? frondData?.frond?.budget
                        : "-"}
                    </span>
                    <span className="leading-normal text-textColor phone:text-base text-[10px]">
                      {frondData?.frond?.currency
                        ? frondData?.frond?.currency
                        : "$"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  Start Date
                </h5>
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={calendar}
                    alt="Calendar"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="leading-normal text-textColor phone:text-base text-[10px]">
                    {startDateArr
                      ? `${startDateArr[0]} ${months[startDateArr[1] - 1]}, ${
                          startDateArr[2]
                        }`
                      : "-"}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  End Date
                </h5>
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={calendar}
                    alt="Calendar"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="leading-normal text-textColor phone:text-base text-[10px]">
                    {endDateArr
                      ? `${endDateArr[0]} ${months[endDateArr[1] - 1]}, ${
                          endDateArr[2]
                        }`
                      : "-"}
                  </span>
                </div>
              </div>
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <h5 className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                  Description
                </h5>
                <div className={`${!frondData?.frond?.desc && "text-center"}`}>
                  <p className="leading-normal text-textColor phone:text-base text-[10px] break-before-all">
                    {frondData?.frond?.desc ? frondData?.frond?.desc : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className="info flex items-start gap-[22px] mb-5">
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-[5px]">
                  <Image
                    src={like}
                    alt="Heart"
                    className="phone:min-w-[18px] phone:min-h-[18px] phone:max-w-[18px] phone:max-h-[18px] min-w-[12px] min-h-[12px] max-w-[12px] max-h-[12px]"
                  />
                  <span className="text-cardGrayColor phone:text-sm text-[10px] leading-normal font-normal">
                    Tags
                  </span>
                </div>
                <div
                  className={`flex items-center phone:text-base text-[10px] ${
                    frondData?.tags?.length === 0 && "justify-center"
                  } gap-2.5 flex-wrap`}
                >
                  {frondData?.tags?.length > 0
                    ? frondData?.tags.map((item, idx) => (
                        <div
                          key={idx}
                          className="py-1.5 px-2.5 rounded-[20px] bg-mainBlue text-white flex justify-center items-center"
                        >
                          <span className="phone:text-sm text-[10px] leading-[16.1px]">
                            {item.name.en}
                          </span>
                        </div>
                      ))
                    : "-"}
                </div>
              </div>
            </div>
          </div>
          <div className="card-img relative">
            <div
              className={`absolute inset-0 ${
                frondData?.image ? "bg-frondDetails" : "bg-textColor"
              } z-20 rounded-[20px]`}
            ></div>

            {frondData?.image ? (
              <div
                style={{
                  backgroundImage: `url(${frondData?.image})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  width: "300px",
                  height: "300px",
                  maxHeight: "300px",
                  borderRadius: "20px",
                  overflow: "hidden",
                }}
              />
            ) : (
              <div className="flex justify-center w-[300px] h-[300px] items-center">
                <Image
                  classNames={{ wrapper: "z-30" }}
                  src={placholderIcon}
                  alt={frondData?.frond?.title}
                  className="w-[46px] h-[50px]"
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default ShareBody;
