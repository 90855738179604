import {
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import closeIcon from "../../Assets/popup/cross-square.svg";
import copyLinkIcon from "../../Assets/popup/fasten.svg";
import { Controller, useForm } from "react-hook-form";
import cardImg from "../../Assets/frond-details/about-frond.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import placeholderImage from "../../Assets/my-fronds/frond/placeholder-image.svg";
import venture from "../../Assets/my-fronds/frond/category/venture.svg";
import other from "../../Assets/my-fronds/frond/category/other.svg";
import home from "../../Assets/my-fronds/frond/category/home.svg";
import event from "../../Assets/my-fronds/frond/category/event.svg";
import escape from "../../Assets/my-fronds/frond/category/escape.svg";
import user1 from "../../Assets/my-fronds/frond/user-1.svg";
import user2 from "../../Assets/my-fronds/frond/user-2.svg";
import calendar from "../../Assets/my-fronds/frond/calendar.svg";
import geolocation from "../../Assets/my-fronds/frond/geolocation.svg";
import facebookIcon from "../../Assets/popup/Facebook.svg";
import xIcon from "../../Assets/popup/TwitterX.svg";
import InstaIcon from "../../Assets/popup/Instagram.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  InstapaperIcon,
  InstapaperShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { Helmet } from "react-helmet";

const ShareFrond = ({
  isOpen,
  onClose,
  stopClosePropagation,
  isModalOpen,
  frondId,
}) => {
  const handleClose = (e) => {
    if (stopClosePropagation) {
      e.preventDefault();
      e.stopPropagation();
    }
    onClose();
  };

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const [startDateArr, setStartDateArr] = useState("");
  const [endDateArr, setEndDateArr] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [frondData, setFrondData] = useState([]);

  let authToken = localStorage.getItem("token");

  const getFrondData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/frond/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data);
      console.log(data);
      if (data?.response?.data?.frond?.start_date) {
        setStartDateArr(data?.response?.data?.frond?.start_date.split("/"));
      }
      if (data?.response?.data?.frond?.end_date) {
        setEndDateArr(data?.response?.data?.frond?.end_date.split("/"));
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (frondId) {
      getFrondData();
    }
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
    },
  });

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [isModalOpen]);

  const handleResize = () => {
    const scrollOutter = document.querySelector(".scroll-outter");
    const scrollInner = document.querySelector(".scroll-inner");

    if (scrollOutter && scrollInner) {
      const scrollOutterWidth = scrollOutter.offsetWidth;
      const scrollInnerWidth = scrollInner.offsetWidth;
      scrollOutter.style.width = `calc(100% + ${
        scrollOutterWidth - scrollInnerWidth
      }px)`;
    }
  };

  const [titleLength, setTitleLength] = useState(15);

  const calculateTitleLength = () => {
    const titleBox = document.querySelector(".title-box");
    if (titleBox) {
      const charNums = Number.parseInt(titleBox.clientWidth / 10);
      setTitleLength(charNums);
    }
  };

  useEffect(() => {
    calculateTitleLength();

    const handleResize = () => {
      calculateTitleLength();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {}, [titleLength]);

  // Share Functions

  const [isLinkCopied, setIsLinkCopied] = useState(false);

  const copyLink = (slug) => {
    const link = `${window.location.origin}/share/frond/${slug}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        setIsLinkCopied(true);
      })
      .catch((err) => {
        toast("Failed to copy link:", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast error-toast",
        });
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999]",
        base: "share-modal",
      }}
      hideCloseButton={true}
      onClick={(e) => e.stopPropagation()}
    >
      <Helmet>
        <meta
          property="og:title"
          content={frondData?.frond?.slug || "Project Title"}
        />
        <meta
          property="og:description"
          content="Check out this amazing project on our platform!"
        />
        <meta
          property="og:image"
          content={frondData?.frond?.image || "default-image-url.jpg"}
        />
        <meta
          property="og:url"
          content={`${window.location.origin}/share/frond/${frondData?.frond?.slug}`}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={frondData?.frond?.slug || "Project Title"}
        />
        <meta
          name="twitter:description"
          content="Check out this amazing project!"
        />
        <meta
          name="twitter:image"
          content={frondData?.frond?.image || "default-image-url.jpg"}
        />
      </Helmet>
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
              <h3 className="phone:text-lg text-sm text-textColor font-bold leading-[20.7px]">
                Share Project
              </h3>
              <div className="flex items-center gap-2">
                {isLinkCopied ? (
                  <h6 className="text-mainBlue phone:text-base text-sm font-normal leading-[18.4px]">
                    Copied
                  </h6>
                ) : (
                  <button
                    className="flex items-center gap-2"
                    onClick={() => copyLink(frondData?.frond?.slug)}
                    aria-label="Copy Link"
                  >
                    <img
                      src={copyLinkIcon}
                      alt="Copy"
                      className="phone:w-[18px] phone:h-[18px] w-[14px] h-[14px]"
                    />
                    <h6 className="text-mainBlue phone:text-base text-sm font-normal leading-[18.4px]">
                      Copy link
                    </h6>
                  </button>
                )}
                <button onClick={handleClose} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </div>
            </ModalHeader>
            <form onSubmit={handleSubmit()}>
              <div className="max-h-[60vh] overflow-y-auto scroll-outter">
                <ModalBody className="w-[100%] scroll-inner">
                  {/* <div className="flex items-center gap-[15px] mb-5">
                    <Image
                      src={cardImg}
                      alt="User Image"
                      className="min-w-[51px] min-h-[51px] max-w-[51px] max-h-[51px] rounded-full"
                    />
                    <Controller
                      name="title"
                      control={control}
                      rules={{
                        required: "Title is required",
                        minLength: {
                          value: 3,
                          message: "Minimum number of characters is 3",
                        },
                        maxLength: {
                          value: 100,
                          message: "Maximum number of characters is 100",
                        },
                        validate: {
                          noTrailingSpace: (value) =>
                            !/\s$/.test(value) ||
                            "Title shouldn't end with a space",
                        },
                        pattern: {
                          value:
                            /^(?! )[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\=]+(?!.*\s{2})[a-zA-Z0-9\-\_\'\"\.\,\!\@\#\$\%\^\&\*\(\)\+\= ]*$/gi,
                          message:
                            "Title may contain characters, numbers and special characters",
                        },
                      }}
                      render={({ field }) => (
                        <Input
                          // onKeyDown={(e) => {
                          //   e.key === "Enter" && e.preventDefault();
                          // }}
                          {...field}
                          type="text"
                          variant="bordered"
                          placeholder="Whats on your mind?"
                          isInvalid={errors.title?.message ? true : false}
                          errorMessage={errors.title?.message}
                          classNames={{
                            base: `input-noMargin ${
                              errors.title?.message ? "h-[80px]" : "h-[51px]"
                            }`,
                            label:
                              "text-textColor font-normal phone:text-sm text-[10px]",
                            input:
                              "border-none px-[18px] phone:text-base text-[10px]",
                            mainWrapper: `${
                              errors.title?.message ? "h-[80px]" : "h-[51px]"
                            }`,
                            innerWrapper: "h-[51px]",
                            inputWrapper: `border-[0.86px] p-0 border-solid share-frond-input ${
                              errors.title?.message
                                ? "invalid-text"
                                : "border-lightWhite"
                            } rounded-[40px] h-[51px] bg-lightWhite`,
                          }}
                        />
                      )}
                    />
                  </div> */}
                  <div className="frond-card flex flex-col gap-y-2 mb-5 relative p-3 bg-white rounded-[14px] border-2 border-solid border-grayBorder no-focus">
                    {frondData?.image ? (
                      <div className="w-full h-[186px] rounded-[10px] mb-0.5 border-2 border-solid border-grayBorder no-focus">
                        <div
                          className="size-full rounded-[10px]"
                          style={{
                            backgroundImage: `url(${frondData.image})`,
                            backgroundSize: "100%",
                            backgroundPosition: "50% 20%",
                            backgroundRepeat: "no-repeat",
                          }}
                        />
                      </div>
                    ) : (
                      <div className="min-w-full h-[146px] rounded-[10px] mb-0.5 flex justify-center items-center border-2 border-solid border-grayBorder no-focus">
                        <img src={placeholderImage} alt="frond" />
                      </div>
                    )}
                    <div className="name-category flex flex-col gap-[5px]">
                      <h5 className="text-textColor phone:text-base text-[12px] font-bold flex-1 title-box">
                        {frondData?.frond?.title.length > titleLength
                          ? `${frondData?.frond?.title.substring(
                              0,
                              titleLength
                            )}...`
                          : frondData?.frond?.title}
                      </h5>
                      <div className="flex items-center gap-2">
                        <Image
                          src={
                            frondData?.frond?.category === "Event"
                              ? event
                              : frondData?.frond?.category === "Escape"
                              ? escape
                              : frondData?.frond?.category === "Home"
                              ? home
                              : frondData?.frond?.category === "Venture"
                              ? venture
                              : frondData?.frond?.category === "Other"
                              ? other
                              : null
                          }
                          alt={frondData?.frond?.category}
                          className="phone:w-[18px] phone:h-[18px] w-[14px] h-[14px]"
                        />
                        <span className="text-cardGrayColor phone:text-base text-[12px] font-normal">
                          {frondData?.frond?.category}
                        </span>
                      </div>
                    </div>
                    <div className="date flex items-center gap-1.5 ms-2.5">
                      <Image
                        src={calendar}
                        alt="Calendar"
                        className="phone:w-[18px] phone:h-[18px] w-[14px] h-[14px]"
                      />
                      <div className="flex items-center phone:text-sm text-[12px] font-normal text-mainBlue opacity-75">
                        <p>
                          {startDateArr &&
                            `${startDateArr[0]} ${
                              months[startDateArr[1] - 1]
                            }, ${startDateArr[2]}`}
                        </p>
                        <span
                          className={`${
                            frondData?.frond?.start_date &&
                            frondData?.frond?.end_date
                              ? "mx-1"
                              : null
                          }`}
                        >
                          -
                        </span>
                        <p>
                          {endDateArr &&
                            `${endDateArr[0]} ${months[endDateArr[1] - 1]}, ${
                              endDateArr[2]
                            }`}
                        </p>
                      </div>
                    </div>
                    <div className="content flex justify-between">
                      <div className="info">
                        <div className="location flex items-center gap-1.5 ms-2.5">
                          <Image
                            src={geolocation}
                            alt="Geolocation"
                            className="phone:w-[18px] phone:h-[18px] w-[14px] h-[14px]"
                          />
                          <p className="phone:text-sm text-[12px] font-normal text-mainBlue opacity-75">
                            {frondData?.frond?.location || "-"}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`users ${
                          frondData?.members?.length > 2 && "w-[60px]"
                        } ${frondData?.members?.length > 1 && "w-[40px]"} ${
                          frondData?.members?.length === 1 && "w-[24px]"
                        }  h-[24px] relative flex self-end justify-end items-center`}
                      >
                        {frondData?.members?.length > 0 && (
                          <img
                            src={frondData?.members[0]?.img_url}
                            alt={frondData?.members[0]?.name}
                            className={`min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] rounded-full absolute z-[10] ${
                              frondData?.members?.length > 1
                                ? "left-0"
                                : "left-0"
                            }  top-0 count-border gradiantBorder`}
                          />
                        )}
                        {frondData?.members?.length > 1 && (
                          <img
                            src={frondData?.members[1]?.img_url}
                            alt={frondData?.members[1]?.name}
                            className={`min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] rounded-full absolute z-[11] ${
                              frondData?.members?.length > 2
                                ? "right-[18px]"
                                : "right-0"
                            } top-0 count-border gradiantBorder`}
                          />
                        )}
                        {frondData?.members?.length > 2 && (
                          <div className="min-w-[24px] min-h-[24px] max-w-[24px] max-h-[24px] flex justify-center items-center count-border gradiantBorder rounded-full absolute z-[12] right-0 top-0">
                            <span className="text-sm font-normal bg-clip-text text-transparent bg-mainGradiant">
                              +{frondData?.members?.length - 2}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-5 justify-center items-center mb-2.5">
                    <Link
                      to={`/share/frond/${frondData?.frond?.slug}`}
                      target="_blank"
                      className="bg-mainGradiant rounded-[27px] py-[14px] px-[25px] h-[46px] flex justify-center items-center w-full text-white font-bold leading-[18.4px]"
                    >
                      Share
                    </Link>
                    <div className="flex items-center gap-5 w-full">
                      <span className="h-[0.86px] bg-cardColor sm:w-[162px] w-[50%]"></span>
                      <span className="text-cardGrayColor text-sm leading-[16.1px]">
                        Or
                      </span>
                      <span className="h-[0.86px] bg-cardColor sm:w-[162px] w-[50%]"></span>
                    </div>
                    <div className="flex items-center justify-center gap-5 w-full">
                      {/* <button>
                        <Image
                          src={facebookIcon}
                          alt="Facebook Share"
                          className="w-[50px] h-[50px]"
                        />
                      </button>

                      <button>
                        <Image
                          src={InstaIcon}
                          alt="Instgram Share"
                          className="w-[50px] h-[50px]"
                        />
                      </button>
                      <button>
                        <Image
                          src={xIcon}
                          alt="X Share"
                          className="w-[50px] h-[50px]"
                        />
                      </button> */}
                      <FacebookShareButton
                        hashtag={`${window.location.origin}/frond/share/${frondData?.frond?.slug}`}
                        url={`${window.location.origin}/frond/share/${frondData?.frond?.slug}`}
                      >
                        <Image src={facebookIcon} />
                      </FacebookShareButton>

                      <TwitterShareButton
                        url={`${window.location.origin}/frond/share/${frondData?.frond?.slug}`}
                      >
                        <Image src={xIcon} />
                      </TwitterShareButton>
                    </div>
                  </div>
                </ModalBody>
              </div>
            </form>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ShareFrond;
