import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Textarea,
} from "@nextui-org/react";
import React, { useContext, useEffect, useState } from "react";
import dots from "../../Assets/community/dots-vertical.svg";
import grayLikesIcon from "../../Assets/community/gray-likes.svg";
import grayCommentsIcon from "../../Assets/community/gray-comments.svg";
import likesIcon from "../../Assets/community/likes.svg";
import commentsIcon from "../../Assets/community/comments.svg";
import axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AddPostModal from "./AddPostModal";
import { PopUpContext } from "Context/PopUpContext";
import EditCommentModal from "./EditCommentModal";

export default function PostLayout({
  userImg,
  postId,
  userName,
  time,
  content,
  likesCount,
  commentsCount,
  postImg,
  isOwner,
  isLiked,
  commentsList,
  likesList,
  isLast,
  getPost,
}) {
  let authToken = localStorage.getItem("token");

  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  const [itemKey, setItemKey] = useState(null);

  const [isEditModal, setIsEditModal] = useState(false);
  const [frondData, setFrondData] = useState([]);
  const [targetId, setTargetId] = useState(null);
  const [targetComment, setTargetComment] = useState(null);

  const [isEditCommentModalOpen, setIsEditCommentModalOpen] = useState(false);

  async function getPostData() {
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/posts/${postId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      setFrondData(data);
      setIsEditModal(true);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  const { setIsAddPost, isAddPost } = useContext(PopUpContext);

  useEffect(() => {
    if (isAddPost) {
      setTargetId(null);
      setTargetComment(null);
    }
  }, [isAddPost]);

  async function deletePost() {
    try {
      const { data } = await axios.delete(
        `https://frond-admin.code-minds.tech/api/posts/${postId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast("Post Deleted", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      setIsAddPost(true);
      setTimeout(() => {
        setIsAddPost(false);
      }, 1000);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  useEffect(() => {
    if (itemKey === "delete") {
      deletePost();
      setItemKey(null);
    } else if (itemKey === "edit") {
      getPostData();
      setItemKey(null);
    } else if (itemKey === "edit comment") {
      setIsEditCommentModalOpen(true);
      setItemKey(null);
    } else if (itemKey === "delete comment") {
      deleteComment();
      setTargetId(null);
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id, comment) => {
    setItemKey(key);
    if (id) {
      setTargetId(id);
    }
    if (comment) {
      setTargetComment(comment);
    }
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  // Like

  async function likePost() {
    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/posts/${postId}/like`,
        {},
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      getPost();
    } catch (error) {}
  }

  // Comment

  const [showCommentBox, setShowCommentBox] = useState(false);

  const {
    control: controlForm1,
    handleSubmit: handleSubmitForm1,
    reset: resetForm1,
    formState: { errors: errorsForm1 },
  } = useForm({
    defaultValues: {
      desc: "",
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [descLength, setDescLength] = useState(0);

  async function commentPost(reqBody) {
    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/posts/${postId}/comment`,
        {
          comment: reqBody.desc,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      resetForm1();
      setDescLength(0);
      getPost();
      setShowCommentBox(false);
    } catch (error) {}
  }

  async function deleteComment() {
    try {
      const { data } = await axios.delete(
        `https://frond-admin.code-minds.tech/api/comments/${targetId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      toast("Comment Deleted", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      setIsAddPost(true);
      setTimeout(() => {
        setIsAddPost(false);
      }, 1000);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
  }

  // Reply

  const {
    control: controlForm2,
    handleSubmit: handleSubmitForm2,
    reset: resetForm2,
    formState: { errors: errorsForm2 },
  } = useForm({
    defaultValues: {
      desc2: "",
    },
  });

  const [commentId, setCommentId] = useState(null);

  const [replyDescLength, setReplyDescLength] = useState(0);

  async function replyComment(reqBody) {
    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/comments/${commentId}/reply`,
        {
          comment: reqBody.desc2,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      resetForm2();
      setReplyDescLength(0);
      getPost();
      setCommentId(null);
    } catch (error) {}
  }

  return (
    <>
      <div
        className={`post bg-white rounded-[20px] border-1 border-solid border-grayBorder p-5 w-full no-focus overflow-hidden ${
          !isLast ? "mb-5" : ""
        }`}
      >
        <div className="flex items-start justify-between gap-2.5 mb-5">
          <div className="flex items-center gap-2.5">
            <Image
              src={userImg}
              alt={userName}
              className="min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px] rounded-full"
            />
            <div>
              <h5 className="mb-[3px] text-textColor font-bold text-base leading-[22px]">
                {userName}
              </h5>
              <p className="text-cardGrayColor text-sm leading-[16.1px]">
                {time}
              </p>
            </div>
          </div>
          {isOwner && (
            <Dropdown
              classNames={{
                content:
                  "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
              }}
              placement="left-start"
            >
              <DropdownTrigger>
                <button className="w-[24px] h-[24px]" onClick={handleDotsClick}>
                  <img
                    src={dots}
                    alt="Dots"
                    className="min-w-[24px] min-h-[24px]"
                  />
                </button>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Post Actions"
                onAction={(key) => openModal(key)}
              >
                <DropdownItem
                  textValue="edit note"
                  key="edit"
                  className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  key="delete"
                  className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                  color="danger"
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        <p className="mb-5 text-textColor leading-[22px]">{content}</p>
        {postImg && (
          <div className="w-5/6 mx-auto max-h-[335px] mb-2.5">
            <Image
              src={postImg}
              alt={content}
              classNames={{ wrapper: "min-w-full max-h-[335px]" }}
              className="w-full rounded-[10px] max-h-[335px] object-cover"
            />
          </div>
        )}
        <div className="flex justify-between mx-auto items-center w-5/6 pb-2.5 mb-2.5 border-b-1 border-solid border-grayBorder">
          <div className="flex items-center gap-1.5">
            <Image
              src={grayLikesIcon}
              alt="Likes"
              className="w-[18px] h-[18px]"
            />
            <p className="text-sm text-cardGrayColor leading-[16.1px]">
              {likesCount} Likes
            </p>
          </div>
          <div className="flex items-center gap-1.5">
            <Image
              src={grayCommentsIcon}
              alt="Comments"
              className="w-[18px] h-[18px]"
            />
            <p className="text-sm text-cardGrayColor leading-[16.1px]">
              {commentsCount} Comments
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between w-9/12 mx-auto">
          <button
            onClick={() => {
              likePost();
            }}
            className="flex items-center justify-start gap-[5px] w-1/2 border-r-2 border-solid border-grayBorder"
          >
            <Image src={likesIcon} alt="Like" className="w-[24px] h-[24px]" />
            <h4
              className={`text-base font-bold ${
                isLiked
                  ? "text-transparent bg-clip-text bg-mainGradiant"
                  : "text-cardGrayColor"
              } leading-[18.4px]`}
            >
              Like
            </h4>
          </button>
          <button
            onClick={() => {
              setShowCommentBox(!showCommentBox);
            }}
            className="flex items-center justify-end gap-[5px] w-1/2"
          >
            <Image
              src={commentsIcon}
              alt="Comment"
              className="w-[24px] h-[24px]"
            />
            <h4 className="text-base font-bold text-cardGrayColor leading-[18.4px]">
              Comment
            </h4>
          </button>
        </div>
        <div
          className={`transition-all duration-300 overflow-hidden ${
            showCommentBox
              ? commentsList.length > 0
                ? commentsList.length === 1
                  ? commentId
                    ? "h-[610px] mt-5"
                    : "h-[390px] mt-5"
                  : commentId
                  ? "h-[690px] mt-5"
                  : "h-[470px] mt-5"
                : commentId
                ? "h-[450px] mt-5"
                : "h-[230px] mt-5"
              : "h-0 mt-0"
          } w-full flex flex-col`}
        >
          {commentsList.length > 0 && (
            <div
              className={`flex-grow overflow-y-auto mb-2.5 ${
                commentsList.length === 1
                  ? commentId
                    ? "min-h-[350px]"
                    : "min-h-[140px]"
                  : commentId
                  ? "min-h-[440px]"
                  : "min-h-[220px]"
              }`}
            >
              {commentsList.length > 0 && (
                <div className="flex flex-col gap-2.5">
                  {commentsList.map((item) => (
                    <div
                      key={item?.id}
                      className="flex justify-between bg-offWhite rounded-[10px] px-2 overflow-hidden"
                    >
                      <div className="p-2.5 flex flex-col overflow-hidden flex-1">
                        <div className="flex items-center gap-2.5 mb-2.5">
                          <div
                            className={`min-w-[45px] min-h-[45px] max-w-[45px] max-h-[45px] rounded-full`}
                            style={{
                              backgroundImage: `url(${item?.user?.img_url})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "100%",
                              objectFit: "cover",
                              backgroundPosition: "50%",
                            }}
                          ></div>
                          <div className="flex flex-col gap-[3px]">
                            <p className="text-textColor font-bold text-base">
                              {item?.user?.name}
                            </p>
                            <p className="text-cardGrayColor text-sm">
                              {timeAgo(item?.updated_at)}
                            </p>
                          </div>
                        </div>
                        <p className="text-textColor leading-[22px] break-before-all ms-[55px] mb-2.5">
                          {item?.comment}
                        </p>
                        <button
                          onClick={() => {
                            if (commentId) {
                              if (commentId === item?.id) {
                                setCommentId(null);
                                resetForm2();
                              } else {
                                setCommentId(null);
                                resetForm2();
                                setCommentId(item?.id);
                              }
                            } else {
                              setCommentId(item?.id);
                            }
                          }}
                          className={`text-white text-sm font-bold w-fit ms-[55px] bg-mainGradiant rounded-md px-2.5 transition-all duration-300 overflow-hidden ${
                            commentId === item?.id ? "h-0" : "h-[28px]"
                          }`}
                        >
                          Reply
                        </button>
                        <div
                          className={`transition-all duration-300 overflow-hidden ${
                            commentId === item?.id ? "min-h-[220px]" : "h-0"
                          }`}
                        >
                          <form onSubmit={handleSubmitForm2(replyComment)}>
                            <Controller
                              name="desc2"
                              control={controlForm2}
                              rules={{
                                required: {
                                  value: true,
                                  message: "Reply is required",
                                },
                                minLength: {
                                  value: 2,
                                  message: "Minimum number of characters is 2",
                                },
                                maxLength: {
                                  value: 500,
                                  message:
                                    "Maximum number of characters is 500",
                                },
                              }}
                              render={({ field }) => (
                                <Textarea
                                  {...field}
                                  onInput={(e) =>
                                    setReplyDescLength(e.target.value.length)
                                  }
                                  variant="bordered"
                                  maxLength={500}
                                  placeholder="Text here..."
                                  labelPlacement="outside"
                                  classNames={{
                                    base: "min-h-[100px] max-h-[100px] mt-5",
                                    label:
                                      "text-textColor font-normal smallPhone:text-sm text-[12px]",
                                    input:
                                      "px-[18px] py-[12px] border-none create-frond-desc smallPhone:text-sm text-[10px]",
                                    innerWrapper:
                                      "p-0 min-h-[100px] max-h-[100px]",
                                    inputWrapper: `border-[0.86px] p-0 border-solid ${
                                      errorsForm2.desc2?.message
                                        ? "invalid-text"
                                        : "border-[#E5E4EB]"
                                    } rounded-[8px] min-h-[100px] max-h-[100px]`,
                                  }}
                                  isInvalid={
                                    errorsForm2.desc2?.message ? true : false
                                  }
                                  errorMessage={errorsForm2.desc2?.message}
                                />
                              )}
                            />
                            {!errorsForm2.desc2?.message &&
                            500 - replyDescLength > 0 ? (
                              <p className="text-[.75rem] text-mainBlue mt-1">
                                {500 - replyDescLength} remaining characters
                              </p>
                            ) : null}
                            <div className="flex gap-2.5 items-center mt-5">
                              <button
                                isDisabled={isDisabled}
                                className="mt-2.5 bg-mainGradiant rounded-lg w-[70px] h-[33px] flex justify-center items-center gap-2"
                              >
                                {isLoading ? (
                                  <span className="flex justify-center items-center text-white">
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                  </span>
                                ) : (
                                  <span className="text-white text-base">
                                    Reply
                                  </span>
                                )}
                              </button>
                              <button
                                className="mt-2.5 bg-mainGradiant rounded-lg w-[70px] h-[33px] flex justify-center items-center gap-2"
                                onClick={() => {
                                  setCommentId(null);
                                  resetForm2();
                                }}
                              >
                                <span className="text-white text-base">
                                  Discard
                                </span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                      {item?.my_comment && (
                        <Dropdown
                          classNames={{
                            content:
                              "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                          }}
                          placement="left-start"
                        >
                          <DropdownTrigger>
                            <button
                              className="w-[24px] h-[24px] mt-2.5"
                              onClick={handleDotsClick}
                            >
                              <img
                                src={dots}
                                alt="Dots"
                                className="min-w-[24px] min-h-[24px]"
                              />
                            </button>
                          </DropdownTrigger>
                          <DropdownMenu
                            aria-label="Post Actions"
                            onAction={(key) =>
                              openModal(key, item?.id, item?.comment)
                            }
                          >
                            <DropdownItem
                              textValue="edit comment"
                              key="edit comment"
                              className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              key="delete comment"
                              className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                              color="danger"
                            >
                              Delete
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          <form onSubmit={handleSubmitForm1(commentPost)}>
            <div className="">
              <Controller
                name="desc"
                control={controlForm1}
                rules={{
                  required: {
                    value: true,
                    message: "Comment is required",
                  },
                  minLength: {
                    value: 2,
                    message: "Minimum number of characters is 2",
                  },
                  maxLength: {
                    value: 500,
                    message: "Maximum number of characters is 500",
                  },
                }}
                render={({ field }) => (
                  <Textarea
                    {...field}
                    onInput={(e) => setDescLength(e.target.value.length)}
                    variant="bordered"
                    maxLength={500}
                    placeholder="Text here..."
                    labelPlacement="outside"
                    classNames={{
                      base: "input-noMargin",
                      label:
                        "text-textColor font-normal smallPhone:text-sm text-[12px]",
                      input:
                        "px-[18px] py-[12px] border-none create-frond-desc smallPhone:text-sm text-[10px]",
                      innerWrapper: "p-0 min-h-[156px] max-h-[116px]",
                      inputWrapper: `border-[0.86px] p-0 border-solid ${
                        errorsForm1.desc?.message
                          ? "invalid-text"
                          : "border-[#E5E4EB]"
                      } rounded-[8px] min-h-[156px] max-h-[156px]`,
                    }}
                    isInvalid={errorsForm1.desc?.message ? true : false}
                    errorMessage={errorsForm1.desc?.message}
                  />
                )}
              />
              {!errorsForm1.desc?.message && 500 - descLength > 0 ? (
                <p className="text-[.75rem] text-mainBlue mt-1">
                  {500 - descLength} remaining characters
                </p>
              ) : null}
            </div>
            <button
              isDisabled={isDisabled}
              type="submit"
              className="mt-2.5 bg-mainGradiant rounded-4xl w-[140px] h-[38px] flex justify-center items-center gap-2 ms-auto"
            >
              {isLoading ? (
                <span className="flex justify-center items-center text-white">
                  <i className="fa-solid fa-spinner fa-spin"></i>
                </span>
              ) : (
                <>
                  <span className="text-white text-lg">Comment</span>
                  <i className="fa-solid fa-comment text-white fa-lg"></i>
                </>
              )}
            </button>
          </form>
        </div>
      </div>
      {isEditModal && (
        <AddPostModal
          isOpen={isEditModal}
          onClose={() => setIsEditModal()}
          frondData={{
            editId: frondData?.id,
            editContent: frondData?.content,
            editLatitude: frondData?.latitude,
            editLongitude: frondData?.longitude,
            editCountry: "",
            editCity: "",
            editLocation: "",
          }}
          editImage={frondData?.media[0]?.original_url}
        />
      )}
      {isEditCommentModalOpen && targetComment && (
        <EditCommentModal
          isOpen={isEditCommentModalOpen}
          onClose={() => setIsEditCommentModalOpen(false)}
          editComment={targetComment}
          editId={targetId}
        />
      )}
    </>
  );
}
