import { createContext, useState } from "react";

export let PopUpContext = createContext();

export default function PopUpContextProvider({ children }) {
    const [isGroupInvite, setIsGroupInvite] = useState(false);
    const [isGroupNotInvite, setIsGroupNotInvite] = useState(false);

    const [isArchiveProject, setIsArchiveProject] = useState(false);
    const [isDeleteProject, setIsDeleteProject] = useState(false);
    const [isShareProject, setIsShareProject] = useState(false);

    const [isEditDetailsOpen, setIsEditDetailsOpen] = useState(false);

    const [isEditTitle, setIsEditTitle] = useState(false);

    const [isEditLocation, setIsEditLocation] = useState(false);

    const [isAddScrapbookImage, setIsAddScrapbookImage] = useState(false);

    const [isAddScrapbookUrl, setIsAddScrapbookUrl] = useState(false);
    const [closeUrlImageModal, setCloseUrlImageModal] = useState(false);

    const [isAddRecord, setIsAddRecord] = useState(false);

    const [isAddNote, setIsAddNote] = useState(false);

    const [isAddLink, setIsAddLink] = useState(false);

    const [isAddMember, setIsAddMember] = useState(false);

    const [isAddTask, setIsAddTask] = useState(false);

    const [isAddEvent, setIsAddEvent] = useState(false);

    const [closeAddTaskModal, setCloseAddTaskModal] = useState(false);

    const [isTaskError, setIsTaskError] = useState(false);
    
    const [edtiTimeline, setEdtiTimeline] = useState("")

    const [isDeleteUpdate, setIsDeleteUpdate] = useState(false)
    const [isAddUpdate, setIsAddUpdate] = useState(false)

    const [isAddScrapbookFile, setIsAddScrapbookFile] = useState(false);

    const [isAddPost, setIsAddPost] = useState(false);

    // Edit Profile

    const [isUpdateProfile, setIsUpdateProfile] = useState(false);
    const [isUpdateProfileFailed, setIsUpdateProfileFailed] = useState(false);

    return (
        <PopUpContext.Provider value={{isArchiveProject, setIsArchiveProject, isEditLocation, setIsEditLocation, isUpdateProfileFailed, setIsUpdateProfileFailed, isDeleteProject, setIsDeleteProject, isShareProject, setIsShareProject, isGroupInvite, setIsGroupInvite,isGroupNotInvite, setIsGroupNotInvite, isEditTitle, setIsEditTitle, isEditDetailsOpen, setIsEditDetailsOpen, isAddScrapbookImage, setIsAddScrapbookImage, isAddScrapbookUrl, setIsAddScrapbookUrl, closeUrlImageModal, setCloseUrlImageModal, isAddRecord, setIsAddRecord, isAddNote, setIsAddNote, isAddLink, setIsAddLink, isAddMember, setIsAddMember, isAddTask, setIsAddTask, isAddEvent, setIsAddEvent, closeAddTaskModal, setCloseAddTaskModal, isTaskError, setIsTaskError, edtiTimeline, setEdtiTimeline, isDeleteUpdate, setIsDeleteUpdate, isAddUpdate, setIsAddUpdate, isAddScrapbookFile, setIsAddScrapbookFile, isAddPost, setIsAddPost, isUpdateProfile, setIsUpdateProfile }}>
            {children}
        </PopUpContext.Provider>
    );
}
