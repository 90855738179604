import { SidebarContext } from "Context/SidebarContext";
import Trending from "components/Community/Trending";
import ControlledTabs from "components/ControlledTabs/ControlledTabs";
import ForYouSideNav from "components/DiscoverScreen/ForYouSideNav";
import Timeline from "components/FrondDetails/Timeline";
import Interests from "components/Profile/Interests";
import ProfileNavbar from "components/ProfileNavbar/ProfileNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import useSize from "hooks/useSize";
import React, { useContext, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

const AuthLayout = () => {
  const { isOpen } = useContext(SidebarContext);
  const { width } = useSize();
  const { pathname } = useLocation();

  const [showTabs, setShowTabs] = useState(false);
  const [showTimeline, setShowTimeline] = useState(false);
  const [showTrending, setShowTrending] = useState(false);
  const [showForYou, setShowForYou] = useState(false);
  const [showInterests, setShowInterests] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  function formatPathName(link) {
    const format = link.split("/")[1];
    return format;
  }
  useEffect(() => {
    const formattedPath = formatPathName(pathname);
    if (formattedPath === "frond") {
      setShowTimeline(true);
    } else {
      setShowTimeline(false);
    }
  }, [pathname]);

  useEffect(() => {
    const formattedPath = formatPathName(pathname);
    if (formattedPath === "community") {
      setShowTrending(true);
    } else {
      setShowTrending(false);
    }
  }, [pathname]);

  useEffect(() => {
    const formattedPath = formatPathName(pathname);
    if (formattedPath === "discover") {
      setShowForYou(true);
    } else {
      setShowForYou(false);
    }
  }, [pathname]);

  useEffect(() => {
    const formattedPath = formatPathName(pathname);
    if (formattedPath === "profile") {
      setShowInterests(true);
    } else {
      setShowInterests(false);
    }
  }, [pathname]);

  useEffect(() => {
    const formattedPath = formatPathName(pathname);
    if (
      formattedPath === "" ||
      formattedPath === "home" ||
      formattedPath === "my-fronds" ||
      formattedPath === "archived-fronds" ||
      formattedPath === "shared-fronds" ||
      formattedPath === "create-frond" ||
      formattedPath === "invitations"
    ) {
      setShowTabs(true);
    } else {
      setShowTabs(false);
    }
  }, [pathname]);

  useEffect(() => {
    const page = document.querySelector(".page");
    let overlayDiv;
    if (isOpen && width < 768) {
      overlayDiv = document.createElement("div");

      overlayDiv.style.position = "fixed";
      overlayDiv.style.top = 0;
      overlayDiv.style.left = 0;
      overlayDiv.style.width = "100vw";
      overlayDiv.style.height = "100vh";
      overlayDiv.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
      overlayDiv.style.zIndex = 999;

      document.body.appendChild(overlayDiv);
    }

    if (isOpen) {
      setTimeout(() => {
        if (showTimeline || showTrending || showForYou || showInterests) {
          page.classList.remove("md:w-[calc(100%-238px)]");
          page.classList.remove("md:ms-[238px]");
          page.classList.remove("lg:w-[calc(100%-441px)]");
          page.classList.remove("lg:ms-[104px]");
          page.classList.remove("md:w-[calc(100%-104px)]");
          page.classList.remove("md:ms-[104px]");
          page.classList.remove("lg:w-[79%]");
          page.classList.remove("lg:ms-[10.5%]");
          page.classList.remove("md:w-[70%]");
          page.classList.remove("md:ms-[15%]");

          page.classList.add("lg:w-[calc(100%-575px)]");
          page.classList.add("lg:ms-[238px]");
          page.classList.add("md:w-[calc(100%-238px)]");
          page.classList.add("md:ms-[238px]");
        } else {
          page.classList.remove("lg:w-[calc(100%-441px)]");
          page.classList.remove("lg:ms-[104px]");
          page.classList.remove("md:w-[calc(100%-104px)]");
          page.classList.remove("md:ms-[104px]");
          page.classList.remove("lg:w-[calc(100%-575px)]");
          page.classList.remove("lg:ms-[238px]");
          page.classList.remove("md:w-[calc(100%-238px)]");
          page.classList.remove("md:ms-[238px]");
          page.classList.remove("lg:w-[79%]");
          page.classList.remove("lg:ms-[10.5%]");
          page.classList.remove("md:w-[70%]");
          page.classList.remove("md:ms-[15%]");

          page.classList.add("md:w-[calc(100%-238px)]");
          page.classList.add("md:ms-[238px]");
        }
      }, 100);
    } else {
      setTimeout(() => {
        if (showTimeline || showTrending || showForYou || showInterests) {
          page.classList.remove("lg:w-[calc(100%-575px)]");
          page.classList.remove("lg:ms-[238px]");
          page.classList.remove("md:w-[calc(100%-238px)]");
          page.classList.remove("md:ms-[238px]");
          page.classList.remove("lg:w-[79%]");
          page.classList.remove("lg:ms-[10.5%]");
          page.classList.remove("md:w-[70%]");
          page.classList.remove("md:ms-[15%]");

          page.classList.add("lg:w-[calc(100%-441px)]");
          page.classList.add("lg:ms-[104px]");
          page.classList.add("md:w-[calc(100%-104px)]");
          page.classList.add("md:ms-[104px]");
        } else {
          page.classList.remove("lg:w-[calc(100%-441px)]");
          page.classList.remove("lg:ms-[104px]");
          page.classList.remove("md:w-[calc(100%-104px)]");
          page.classList.remove("md:ms-[104px]");
          page.classList.remove("lg:w-[calc(100%-575px)]");
          page.classList.remove("lg:ms-[238px]");
          page.classList.remove("md:w-[calc(100%-238px)]");
          page.classList.remove("md:ms-[238px]");

          if (formatPathName(pathname) !== "my-tasks") {
            page.classList.add("lg:w-[79%]");
            page.classList.add("lg:ms-[10.5%]");
            page.classList.add("md:w-[70%]");
            page.classList.add("md:ms-[15%]");
          } else {
            page.classList.remove("lg:w-[79%]");
            page.classList.remove("lg:ms-[10.5%]");
            page.classList.remove("md:ms-[15%]");
            page.classList.add("md:w-[calc(100%-94px)]");
            page.classList.add("md:ms-[104px]");
          }
        }
      }, 100);
    }

    setTimeout(() => {
      setIsLoading(true);
    }, 2000);

    return () => {
      if (overlayDiv) {
        document.body.removeChild(overlayDiv);
      }
    };
  }, [
    isOpen,
    showTimeline,
    showTrending,
    showForYou,
    showInterests,
    width,
    pathname,
  ]);

  return (
    <>
      <ProfileNavbar />
      <div className="wrapper flex items-start phone:m-5 m-2.5 gap-5 relative min-h-[calc(100vh-109px)]">
        <div className="fixed left-0 bottom-0 w-[273.26px] h-[305.17px] z-10 bg-authLayoutBuble"></div>
        <div className="fixed right-0 top-[139px] w-[187px] h-[250px] z-10 bg-authLayoutPrint"></div>
        <Sidebar />
        <div
          className={`page relative z-20 w-[calc(100%-54px)] transition-all duration-100 ms-[54px] overflow-hidden`}
        >
          {showTabs && <ControlledTabs />}
          <Outlet />
        </div>
        {showTimeline && <Timeline />}
        {showTrending && <Trending />}
        {showForYou && <ForYouSideNav />}
        {showInterests && <Interests />}
      </div>
    </>
  );
};

export default AuthLayout;
