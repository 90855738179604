import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const useMembers = (frondId, authToken) => {
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getMembers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/members`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setMembers(data || []);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: 'bottom-right',
        hideProgressBar: true,
        className: 'rounded-4xl custom-toast error-toast',
      });
    }
    setIsLoading(false);
  }, [frondId, authToken]);

  useEffect(() => {
    if (frondId) {
      getMembers();
    }
  }, [frondId, getMembers]);

  return { members, isLoading, getMembers };
};

export default useMembers;
