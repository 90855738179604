import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import axios from "axios";
import ProjectControl from "components/ProjectControl/ProjectControl";
import { Image } from "@nextui-org/react";
import DataLoader from "pages/DataLoader/DataLoader";
import { SearchFrondContext } from "Context/SearchFrondContext";
import { toast } from "react-toastify";
import FrondCard from "components/FrondCard/FrondCard";
import empty from "../../Assets/empty-screens/empty-shared-fronds.svg";
import { ViewProjectsContext } from "Context/ViewProjectsContext";
import user1 from "../../Assets/my-fronds/frond/user-1.svg";
import user2 from "../../Assets/my-fronds/frond/user-2.svg";
import { SortContext } from "Context/SortContext";
import { FilterContext } from "Context/FilterContext";
import { useQuery } from "react-query";
import { PopUpContext } from "Context/PopUpContext";

const SharedFronds = () => {
  const { searchValue, setSearchValue } = useContext(SearchFrondContext);

  const authToken = localStorage.getItem("token");

  const fetchFronds = async (authToken) => {
    const { data } = await axios.get(
      "https://frond-admin.code-minds.tech/api/sharedfronds",
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return data.response.data.sharedFronds;
  };

  const { isShareProject } = useContext(PopUpContext);

  const {
    data: fronds = [],
    isLoading,
    isError,
    error,
    refetch,
  } = useQuery(["SharedFronds"], () => fetchFronds(authToken));

  useEffect(() => {
    if (isShareProject) {
      refetch();
    }
  }, [isShareProject]);

  if (isError) {
    toast(error?.response?.data?.message || "Failed to fetch fronds", {
      autoClose: 1500,
      position: "bottom-right",
      hideProgressBar: true,
      className: "rounded-4xl custom-toast error-toast",
    });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { sharedFrondsView, setPageType } = useContext(ViewProjectsContext);

  useLayoutEffect(() => {
    setSearchValue("");

    setPageType("shared-fronds");
  }, []);

  const { sharedSortTypes } = useContext(SortContext);
  const { sharedFilterCategory, sharedFilterTags } = useContext(FilterContext);

  const getNormalizedCategory = (category) => {
    const normalizedCategories = {
      Events: "Event",
      Ventures: "Venture",
      Others: "Other",
      Escapes: "Escape",
    };

    return normalizedCategories[category] || category.slice(0);
  };

  const normalizedFilterCategories = () => {
    if (sharedFilterCategory?.length > 0) {
      return sharedFilterCategory.map(getNormalizedCategory);
    }
    return [];
  };

  const getFilteredFronds = () => {
    let filteredFronds = [...fronds];
    const normalizedCategories = normalizedFilterCategories();

    if (sharedSortTypes) {
      if (normalizedCategories?.length > 0) {
        filteredFronds = filteredFronds?.filter((item) => {
          const itemCategories = Array.isArray(item.frond.category)
            ? item.frond.category
            : [item.frond.category];

          return itemCategories.some((category) => {
            const normalizedCategory = getNormalizedCategory(category);
            return normalizedCategories.includes(normalizedCategory);
          });
        });
      }

      if (sharedFilterTags?.length > 0) {
        filteredFronds = filteredFronds?.filter((item) => {
          const itemTags = Array.isArray(item.tags) ? item.tags : [];

          const itemTagNames = itemTags.map((tag) => tag.name.en);

          const filterTagNames = sharedFilterTags.map(
            (filterTag) => filterTag.text
          );

          return itemTagNames.some((tagName) =>
            filterTagNames.includes(tagName)
          );
        });
      }

      if (sharedSortTypes === "Name (A-Z)") {
        filteredFronds?.sort((a, b) => {
          const titleA = a.frond.title || "";
          const titleB = b.frond.title || "";
          return titleA.localeCompare(titleB, undefined, {
            sensitivity: "base",
          });
        });
      } else if (sharedSortTypes === "Name (Z-A)") {
        filteredFronds?.sort((a, b) => {
          const titleA = a.frond.title || "";
          const titleB = b.frond.title || "";
          return titleB.localeCompare(titleA, undefined, {
            sensitivity: "base",
          });
        });
      } else if (sharedSortTypes === "Start Date") {
        filteredFronds?.sort((a, b) => {
          const parseDate = (dateStr) => {
            if (!dateStr) return null;
            const [day, month, year] = dateStr.split("/");
            return new Date(`${year}-${month}-${day}`);
          };

          const dateA = a.frond.start_date
            ? parseDate(a.frond.start_date)
            : null;
          const dateB = b.frond.start_date
            ? parseDate(b.frond.start_date)
            : null;

          if (dateA === null) return 1;
          if (dateB === null) return -1;

          return dateA - dateB;
        });
      } else if (sharedSortTypes === "End Date") {
        filteredFronds?.sort((a, b) => {
          const parseDate = (dateStr) => {
            if (!dateStr) return null;
            const [day, month, year] = dateStr.split("/");
            return new Date(`${year}-${month}-${day}`);
          };

          const dateA = a.frond.end_date ? parseDate(a.frond.end_date) : null;
          const dateB = b.frond.end_date ? parseDate(b.frond.end_date) : null;

          if (dateA === null) return 1;
          if (dateB === null) return -1;

          return dateA - dateB;
        });
      } else if (sharedSortTypes === "Importance (High - Low)") {
        filteredFronds?.sort((a, b) => {
          const priorityMap = {
            High: 3,
            Medium: 2,
            Low: 1,
            null: 0,
          };

          const var1 = a.frond.priority ? priorityMap[a.frond.priority] : 0;
          const var2 = b.frond.priority ? priorityMap[b.frond.priority] : 0;

          return var2 - var1;
        });
      } else if (sharedSortTypes === "Importance (Low - High)") {
        filteredFronds?.sort((a, b) => {
          const priorityMap = {
            High: 3,
            Medium: 2,
            Low: 1,
          };

          const var1 = a.frond.priority ? priorityMap[a.frond.priority] : 0;
          const var2 = b.frond.priority ? priorityMap[b.frond.priority] : 0;

          if (var1 === 0) return 1;
          if (var2 === 0) return -1;

          return var1 - var2;
        });
      } else if (sharedSortTypes === "Last Updated") {
        filteredFronds?.sort((a, b) => {
          const dateA = a.frond.updated_at
            ? new Date(a.frond.updated_at)
            : null;
          const dateB = b.frond.updated_at
            ? new Date(b.frond.updated_at)
            : null;

          if (dateA === null) return 1;
          if (dateB === null) return -1;

          return dateB - dateA;
        });
      }
    }

    if (searchValue) {
      filteredFronds = filteredFronds?.filter((frond) =>
        (frond?.frond?.title || "")
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
    }

    return filteredFronds;
  };

  const filteredFronds = getFilteredFronds();

  const ITEMS_PER_PAGE = 12;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (currentPage > Math.ceil(filteredFronds.length / ITEMS_PER_PAGE)) {
      setCurrentPage(
        Math.max(Math.ceil(filteredFronds.length / ITEMS_PER_PAGE), 1)
      );
    }
  }, [filteredFronds, currentPage]);

  const totalPages = Math.ceil(filteredFronds.length / ITEMS_PER_PAGE);
  const currentItems = filteredFronds.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  return (
    <section
      className={`my-fronds border-2 border-solid border-grayBorder bg-white rounded-4xl no-focus phone:p-5 px-2.5 py-[15px] ${
        filteredFronds.length === 0 ? "min-h-[calc(100vh-192px)]" : ""
      }`}
    >
      {isError ? (
        <div className="text-white bg-cardColor py-3 text-center rounded-15px text-sm">
          <span className="bg-clip-text text-transparent bg-mainGradiant font-bold">
            {error?.response?.data?.message || "An error occurred"}
          </span>
        </div>
      ) : (
        <>
          <ProjectControl page={"shared-fronds"} />
          {isLoading ? (
            <div className="min-h-[400px] flex justify-center items-center">
              <DataLoader />
            </div>
          ) : filteredFronds?.length > 0 ? (
            <>
              <div
                className={`grid ${
                  sharedFrondsView === "grid"
                    ? "2xl:grid-cols-4 min-[1070px]:grid-cols-3 sm:grid-cols-2 custom-media"
                    : "grid-cols-1"
                }  phone:gap-5 gap-2.5`}
              >
                {currentItems.map((item, idx) => (
                  <FrondCard
                    key={idx}
                    frondId={item?.frond?.id}
                    mainImg={item?.image}
                    title={item?.frond?.title}
                    category={item?.frond?.category}
                    startDate={item?.frond?.start_date}
                    endDate={item?.frond?.end_date}
                    location={item?.frond?.location}
                    isShared={true}
                    userOne={
                      item?.members?.length > 0 && item?.members[0]?.img_url
                    }
                    userTwo={
                      item?.members?.length > 1 && item?.members[1]?.img_url
                    }
                    count={
                      item?.members?.length > 2 && item?.members?.length - 2
                    }
                  />
                ))}
              </div>
              {filteredFronds?.length > 12 && (
                <div className="flex justify-center items-center gap-2.5 mt-6">
                  <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className="smallPhone:px-4 px-2.5 py-2 smallPhone:text-base text-xs bg-mainGradiant rounded-xl text-white font-medium disabled:opacity-50"
                  >
                    Prev
                  </button>
                  <span className="smallPhone:text-lg text-sm text-textColor font-semibold">
                    Page {currentPage} of {totalPages}
                  </span>
                  <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="smallPhone:px-4 px-2.5 py-2 smallPhone:text-base text-xs bg-mainGradiant rounded-xl text-white font-medium disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-col justify-center items-center translate-y-[-43.2px] gap-6 min-h-[calc(100vh-310.3px)]">
              <Image src={empty} alt="No fronds" />
              <p className="text-cardGrayColor leading-[20.7px] text-lg font-bold w-[281px] mx-auto text-center">
                You don’t have any shared projects
              </p>
            </div>
          )}
        </>
      )}
    </section>
  );
};

export default SharedFronds;
