import React, { useContext, useEffect, useState } from "react";
import closeIcon from "../../../Assets/popup/cross-square.svg";
import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Tab,
  Tabs,
} from "@nextui-org/react";
import "../../FrondDetails/modalStyle.css";
import { useParams } from "react-router-dom";
import { PopUpContext } from "Context/PopUpContext";
import TimelineAddTask from "./TimelineAddTask";
import TimelineAddEvent from "./TimelineAddEvent";

const AddTaskEvent = ({ isOpen, onClose, isModalOpen, targetId }) => {
  const [activeLink, setActiveLink] = useState("milestone");
  const { frondId } = useParams();
  const { isAddEvent, closeAddTaskModal, isTaskError, edtiTimeline } =
    useContext(PopUpContext);

  useEffect(() => {
    if (closeAddTaskModal) {
      onClose();
    }
  }, [closeAddTaskModal]);

  const handleResize = () => {
    const scrollOutter = document.querySelector(".scroll-outter");
    const scrollInner = document.querySelector(".scroll-inner");

    if (scrollOutter && scrollInner) {
      const scrollOutterWidth = scrollOutter.offsetWidth;
      const scrollInnerWidth = scrollInner.offsetWidth;
      scrollOutter.style.width = `calc(100% + ${
        scrollOutterWidth - scrollInnerWidth
      }px)`;
    }

    const scrollOutter2 = document.querySelector(".scroll-outter2");
    const scrollInner2 = document.querySelector(".scroll-inner2");

    if (scrollOutter2 && scrollInner2) {
      const scrollOutterWidth2 = scrollOutter2.offsetWidth;
      const scrollInnerWidth2 = scrollInner2.offsetWidth;
      scrollOutter2.style.width = `calc(100% + ${
        scrollOutterWidth2 - scrollInnerWidth2
      }px)`;
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeLink, isAddEvent]);

  useEffect(() => {
    if (activeLink) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [activeLink]);

  useEffect(() => {
    if (isAddEvent) {
      handleResize();
    }
  }, [isAddEvent]);

  useEffect(() => {
    if (isTaskError) {
      handleResize();
    }
  }, [isTaskError]);

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        handleResize();
      }, 1000);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (edtiTimeline === "milestone") {
      setActiveLink("milestone");
    } else {
      setActiveLink("task");
    }
  }, [targetId]);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onClose}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] overflow-hidden",
        footer:
          "justify-center items-center absolute z-30 left-0 right-0 bottom-0 h-[60px] bg-white",
        body: "px-5 mb-2.5",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <div>
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
                <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                  {targetId ? "Edit" : "Add New"}
                </h3>
                <button onClick={closeModal} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </ModalHeader>
              <Tabs
                selectedKey={activeLink}
                onSelectionChange={setActiveLink}
                variant="underlined"
                aria-label="Tabs"
                classNames={{
                  panel: "px-0",
                  base: "w-full relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[2px] after:bg-grayBorder after:z-[10] no-focus",
                  tabList: "w-full justify-evenly relative z-20",
                  tab: "w-fit",
                  tabContent:
                    "text-cardGrayColor font-normal transition-all duration-100 phone:text-base text-xs leading-[18.4px] group-data-[selected=true]:font-bold group-data-[selected=true]:text-transparent group-data-[selected=true]:bg-mainGradiant group-data-[selected=true]:bg-clip-text",
                  cursor:
                    "bg-mainGradiant z-20 bottom-[-4px] rounded-[6px] h-[2px] w-full",
                }}
              >
                {activeLink === "milestone" && targetId ? (
                  <Tab key={"milestone"} title={"Milestone"}>
                    <div className="max-h-[60vh] overflow-y-auto scroll-outter">
                      <ModalBody className="w-[100] item-center gap-5 scroll-inner">
                        <TimelineAddEvent
                          frondId={frondId}
                          eventId={targetId}
                        />
                      </ModalBody>
                    </div>
                  </Tab>
                ) : (
                  !targetId && (
                    <Tab key={"milestone"} title={"Milestone"}>
                      <div className="max-h-[60vh] overflow-y-auto scroll-outter">
                        <ModalBody className="w-[100] item-center gap-5 scroll-inner">
                          <TimelineAddEvent
                            frondId={frondId}
                            eventId={targetId}
                          />
                        </ModalBody>
                      </div>
                    </Tab>
                  )
                )}
                {activeLink === "task" && targetId ? (
                  <Tab key={"task"} title={"Task"}>
                    <div className="max-h-[60vh] overflow-y-auto scroll-outter2">
                      <ModalBody className="w-[100] item-center gap-5 scroll-inner2">
                        <TimelineAddTask frondId={frondId} taskId={targetId} />
                      </ModalBody>
                    </div>
                  </Tab>
                ) : (
                  !targetId && (
                    <Tab key={"task"} title={"Task"}>
                      <div className="max-h-[60vh] overflow-y-auto scroll-outter2">
                        <ModalBody className="w-[100] item-center gap-5 scroll-inner2">
                          <TimelineAddTask
                            frondId={frondId}
                            taskId={targetId}
                          />
                        </ModalBody>
                      </div>
                    </Tab>
                  )
                )}
              </Tabs>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
};

export default AddTaskEvent;
