import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import closeIcon from "../../Assets/popup/cross-square.svg";
import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import RemoveComponent from "../../pages/CreateAFrond/RemoveComponent";
import "../../components/FrondDetails/modalStyle.css";
import { AuthContext } from "Context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import removeImg from "../../Assets/frond-details/removed-img.svg";
import placholderIcon from "../../Assets/frond-details/placholder-icon.svg";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";

// css
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

import uplodaIcon from "../../Assets/create-frond/Upload.svg";
import { useUser } from "@clerk/clerk-react";
import { PopUpContext } from "Context/PopUpContext";

const UserImageModal = ({
  isOpen,
  onClose,

  editImage,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const imgTitle = `<Image src=${uplodaIcon} alt="Upload" /> <p>Drag and Drop Image here</p> <p>Or</p> <span>Browse Images</span>`;

  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginImageEdit
    // FilePondPluginFileEncode,
  );

  const { user } = useUser();

  const [files, setFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);

  useEffect(() => {
    if (editImage && user?.hasImage) {
      setImagePreview(editImage);
    }
  }, [editImage]);

  useEffect(() => {
    if (files.length > 0) {
      setIsDisabled(false);
    }
  }, [files]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      image: "",
    },
  });

  const { token } = useContext(AuthContext);

  useEffect(() => {
    if (files.length > 0) {
      setIsDisabled(false);
    }
  }, [files]);

  const { setIsUpdateProfile } = useContext(PopUpContext);

  async function editFrond(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);

    try {
      if (files?.length > 0) {
        await user
          .setProfileImage({
            file: uploadedFile,
          })
          .then(async (res) => {
            await axios.put(
              "/user",
              {
                img_url: res.publicUrl,
              },
              {
                headers: {
                  Accept: "application/json",
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );
          });

        setIsUpdateProfile(true);
        setTimeout(() => {
          setIsUpdateProfile(false);
        }, 1000);
        onClose();

        toast("Changes Saved", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
      } else {
        if (editImage && user?.hasImage) {
          deleteImg();
        } else {
          onClose();
          reset();
        }
      }
    } catch (error) {
      toast("Failed to update image", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  async function deleteImg() {
    setIsLoading(true);
    setIsDisabled(true);

    try {
      await user.setProfileImage({ file: null });

      await user.reload();

      await axios.put(
        "/user",
        {
          img_url: user?.imageUrl,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast("Changes Saved", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast success-toast",
      });

      setIsUpdateProfile(true);
      setTimeout(() => {
        setIsUpdateProfile(false);
      }, 1000);
      onClose();
      reset();
    } catch (error) {
      toast("Failed to update image", {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }

    setIsDisabled(false);
    setIsLoading(false);
  }

  const [imgAnimation, setImgAnimation] = useState(false);

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={() => {
        onClose();
      }}
      placement="center"
      backdrop="opaque"
      classNames={{
        backdrop: "z-[9999]",
        wrapper: "z-[99999] overflow-hidden",
        footer: "justify-center items-center",
        body: "px-[30px] mb-2.5",
        closeButton: "hidden",
        base: "rounded-[20px] p-5",
      }}
    >
      <div>
        <ModalContent>
          {(closeModal) => (
            <>
              <ModalHeader className="flex justify-between gap-2.5 items-center p-0 pb-[15px] pt-[15px] sm:px-5 px-2.5 mb-[15px] relative after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[0.5px] after:bg-grayBorder">
                <h3 className="text-lg text-textColor font-bold leading-[10.35px]">
                  Edit Image
                </h3>
                <button onClick={closeModal} aria-label="Close">
                  <img src={closeIcon} alt="Close" className="w-6 h-6" />
                </button>
              </ModalHeader>
              <form onSubmit={handleSubmit(editFrond)}>
                <div className="max-h-[60vh] overflow-y-auto w-[calc(100%+17px)]">
                  <ModalBody className="w-[calc(100%-17px)] item-center">
                    <div className="relative about-edit-img sm:min-h-[295px] sm:max-h-[295px] min-h-[200px] max-h-[200px] sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%]">
                      {imagePreview && (
                        <div
                          className={`absolute right-0 left-0 top-0 z-50 border-1 border-dashed border-[#BBBBBB] rounded-[20px] transition-all duration-400 no-focus no-border ${
                            !imgAnimation ? "h-[100%]" : "h-0"
                          }`}
                        >
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setImgAnimation(true);
                              setTimeout(() => {
                                setImagePreview(null);
                                setImgAnimation(false);
                                // deleteImg();
                              }, 400);
                            }}
                            className={`absolute right-[25px] top-[25px] transition-all duration-400 ${
                              !imgAnimation ? "opacity-1" : "opacity-0"
                            }`}
                          >
                            <Image
                              src={removeImg}
                              alt="Remove Image"
                              className="w-[24px] h-[24px]"
                            />
                          </button>
                          <img
                            src={imagePreview}
                            alt="Preview"
                            className={`w-full ${
                              !imgAnimation ? "h-[100%]" : "h-0"
                            } rounded-[20px] transition-all duration-400`}
                          />
                        </div>
                      )}
                      <FilePond
                        files={files}
                        allowReorder={true}
                        allowMultiple={false}
                        allowFileEncode={false}
                        onupdatefiles={setFiles}
                        onpreparefile={(file, output) => {
                          setUploadedFile(output);
                        }}
                        imageResizeTargetWidth={300}
                        imageResizeTargetHeight={300}
                        imageResizeUpscale={false}
                        imageResizeMode="force"
                        acceptedFileTypes={[
                          "image/png",
                          "image/jpeg",
                          "image/gif",
                          "image/bmp",
                          "image/tiff",
                          "image/webp",
                        ]}
                        name="file"
                        allowImageEdit={false}
                        labelIdle={imgTitle}
                      />
                    </div>
                  </ModalBody>
                </div>
                <ModalFooter className="shadow-modalShadow">
                  <button
                    disabled={isDisabled}
                    type="submit"
                    className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] sm:w-[234px] w-[117px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
                  >
                    {isLoading ? (
                      <span className="flex justify-center items-center text-white">
                        <i className="fa-solid fa-spinner fa-spin"></i>
                      </span>
                    ) : (
                      "Save"
                    )}
                  </button>
                </ModalFooter>
              </form>
            </>
          )}
        </ModalContent>
      </div>
    </Modal>
  );
};

export default UserImageModal;
