import React, { useContext, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Image,
  Tooltip,
} from "@nextui-org/react";
import dots from "../../../Assets/scrapbook/dots-vertical.svg";
import { PopUpContext } from "Context/PopUpContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddTask from "./AddTask";
import DeleteTaskModal from "./DeleteTaskModal";
import useSettings from "hooks/useSettings";
import { SettingsContext } from "Context/SettingsContext";

export default function ActiveTasks() {
  const [targetId, setTargetId] = useState(null);
  const [itemKey, setItemKey] = useState(null);
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [frondData, setFrondData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { frondId } = useParams();
  const authToken = localStorage.getItem("token");

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const timeAgo = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffInSeconds = Math.floor((now - date) / 1000);

    const timeUnits = [
      { name: "year", seconds: 31536000 },
      { name: "month", seconds: 2592000 },
      { name: "day", seconds: 86400 },
      { name: "hour", seconds: 3600 },
      { name: "minute", seconds: 60 },
      { name: "second", seconds: 1 },
    ];

    for (const unit of timeUnits) {
      const interval = Math.floor(diffInSeconds / unit.seconds);
      if (interval >= 1) {
        return `${interval} ${unit.name}${interval > 1 ? "s" : ""} ago`;
      }
    }

    return "just now";
  };

  const getTasks = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/projects/${frondId}/tasks`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const tasks = data?.response?.data?.filter(
        (item) => item?.task?.status === "pending"
      );

      setFrondData(tasks);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getTasks();
  }, [frondId]);

  const { isAddTask } = useContext(PopUpContext);

  useEffect(() => {
    if (isAddTask) {
      getTasks();
    }
  }, [isAddTask]);

  useEffect(() => {
    if (itemKey === "delete") {
      setOpenDeleteModal(true);
      setItemKey(null);
    } else if (itemKey === "edit") {
      setEditTaskModal(true);
      setItemKey(null);
    }
  }, [itemKey]);

  const openModal = (key, id) => {
    setItemKey(key);
    setTargetId(id);
  };

  const handleDotsClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  useSettings({ projectId: frondId });
  const { frondSettings } = useContext(SettingsContext);

  const [showTask, setShowTask] = useState(false);

  useEffect(() => {
    if (frondSettings.can_edit_tasks && !frondSettings.is_owner) {
      setShowTask(true);
    } else if (frondSettings.is_owner) {
      setShowTask(true);
    } else {
      setShowTask(false);
    }
  }, [frondSettings]);

  // Pagination

  // Pagination

  const ITEMS_PER_PAGE = 9;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (currentPage > Math.ceil(frondData.length / ITEMS_PER_PAGE)) {
      setCurrentPage(Math.max(Math.ceil(frondData.length / ITEMS_PER_PAGE), 1));
    }
  }, [frondData, currentPage]);

  const totalPages = Math.ceil(frondData.length / ITEMS_PER_PAGE);
  const currentItems = frondData.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="pending-tasks">
        {frondData.length > 0 && (
          <div className="flex flex-col gap-[15px]">
            {currentItems.map((item, idx) => (
              <div
                key={item?.task?.id}
                className={`flex justify-between w-full gap-2.5 ${
                  idx !== currentItems.length - 1
                    ? "pb-[15px] border-b-1 border-solid border-b-grayBorder"
                    : ""
                }`}
              >
                <div className="flex items-start gap-2.5">
                  <Image
                    src={item?.owner?.img_url}
                    className="phone:min-w-[45px] phone:min-h-[45px] max-w-[30px] max-h-[30px] rounded-full"
                    alt={item?.owner?.name}
                  />
                  <div>
                    <h4 className="text-base text-textColor font-bold leading-[18.4px] mb-[3px] flex items-center">
                      <span>{item?.owner?.name || "-"}</span>
                      <span className="mx-1 text-cardGrayColor font-normal">
                        to
                      </span>
                      {item?.assigned_to?.length > 0 ? (
                        item?.assigned_to?.length === 1 ? (
                          <span>{item?.assigned_to[0]?.name}</span>
                        ) : (
                          <div className="flex items-center gap-2">
                            {item?.assigned_to.map((subItem) => (
                              <Tooltip
                                key={subItem?.id}
                                content={subItem?.name}
                              >
                                <Image
                                  src={subItem?.img_url}
                                  classNames={{
                                    wrapper:
                                      "min-w-[30px] min-h-[30px] max-w-[30px] max-h-[30px] rounded-full",
                                  }}
                                  className="rounded-full min-w-[30px] min-h-[30px] max-w-[30px] max-h-[30px] "
                                />
                              </Tooltip>
                            ))}
                          </div>
                        )
                      ) : (
                        <span>-</span>
                      )}
                    </h4>
                    <p className="text-sm text-cardGrayColor mb-2.5">
                      <span className="me-2.5">
                        {timeAgo(item?.task?.created_at) || "-"}
                      </span>
                      <span className="text-sm leading-[22px] text-blueTimeline">
                        {item?.task?.status[0].toUpperCase() +
                          item?.task?.status.slice(1) || "-"}
                      </span>
                    </p>
                    {showTask ? (
                      <button
                        type="button"
                        onClick={() => openModal("edit", item?.task?.id)}
                        className="text-mainBlue leading-[22px] font-bold mb-[4px]"
                      >
                        {item?.task?.title || "-"}
                      </button>
                    ) : (
                      <span className="text-mainBlue leading-[22px] font-bold mb-[4px]">
                        {item?.task?.title || "-"}
                      </span>
                    )}
                    <p className="text-textColor leading-[22px] break-before-all">
                      {item?.task?.description || "-"}
                    </p>
                  </div>
                </div>
                {showTask ? (
                  <Dropdown
                    classNames={{
                      content:
                        "min-w-[88px] max-w-[88px] py-0 px-[5px] rounded-[10px]",
                    }}
                    placement="left-start"
                  >
                    <DropdownTrigger>
                      <button
                        className="w-[24px] h-[24px]"
                        onClick={handleDotsClick}
                      >
                        <img
                          src={dots}
                          alt="Dots"
                          className="min-w-[24px] min-h-[24px]"
                        />
                      </button>
                    </DropdownTrigger>
                    <DropdownMenu
                      aria-label="Frond Actions"
                      onAction={(key) => openModal(key, item?.task?.id)}
                    >
                      <DropdownItem
                        textValue="edit note"
                        key="edit"
                        className="text-textColor text-sm font-normal py-[2px] px-[5px] relative after:absolute after:left-0 after:right-0 after:bottom-[-2.5px] after:h-px after:bg-grayBorder mb-[5px] selected-action"
                      >
                        Edit
                      </DropdownItem>
                      <DropdownItem
                        key="delete"
                        className="text-[#EB6270] text-sm font-normal py-[2px] px-[5px] selected-action"
                        color="danger"
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : null}
              </div>
            ))}
          </div>
        )}
        {frondData?.length > 9 && (
          <div className="flex justify-center items-center gap-2.5 mt-6">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="smallPhone:px-4 px-2.5 py-2 smallPhone:text-base text-xs bg-mainGradiant rounded-xl text-white font-medium disabled:opacity-50"
            >
              Prev
            </button>
            <span className="smallPhone:text-lg text-sm text-textColor font-semibold">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className="smallPhone:px-4 px-2.5 py-2 smallPhone:text-base text-xs bg-mainGradiant rounded-xl text-white font-medium disabled:opacity-50"
            >
              Next
            </button>
          </div>
        )}
      </section>
      {editTaskModal && targetId && (
        <AddTask
          isOpen={editTaskModal}
          onClose={() => setEditTaskModal(false)}
          frondId={frondId}
          isModalOpen={true}
          taskId={targetId}
        />
      )}
      {openDeleteModal && targetId && (
        <DeleteTaskModal
          isOpen={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          taskId={targetId}
        />
      )}
    </>
  );
}
