import react, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AuthContext } from "Context/AuthContext";
import { toast } from "react-toastify";
import axios from "axios";
import removeImgIcon from "../../../../Assets/scrapbook/delete-img.svg";
import linkImg from "../../../../Assets/landing/feedbackCardImg.svg";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImageEdit from "filepond-plugin-image-edit";

// css
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

// Import Doka. For testing purpose only, if you're intrested in using Doka
// in your project please purchase a license at https://pqina.nl/doka/
import "../../vendor/doka.min.css";
import { create } from "../../vendor/doka.esm.min.js";
import uplodaIcon from "../../../../Assets/create-frond/Upload.svg";
import { Image, ModalFooter } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { PopUpContext } from "Context/PopUpContext";

const EditImageTab = ({ frondId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const imgTitle = `<Image src=${uplodaIcon} alt="Upload" /> <p>Drag and Drop Image here or</p><span class="block">Browse Images</span>`;

  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginImageResize,
    FilePondPluginImageTransform,
    FilePondPluginImageEdit
    // FilePondPluginFileEncode,
  );

  const [files, setFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      image: "",
    },
  });

  let authToken = localStorage.getItem("token");
  const { setIsAddScrapbookImage, setCloseUrlImageModal } =
    useContext(PopUpContext);

  async function addImage(reqBody) {
    setIsLoading(true);
    setIsDisabled(true);

    try {
      const { data } = await axios.post(
        `https://frond-admin.code-minds.tech/api/scrapbook-images/${frondId}`,
        {
          image: uploadedFile,
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (data.status === 200) {
        toast("Image created successfully", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
        getScrapbookImages();
        setFiles([]);
        setUploadedFile([]);
        setIsAddScrapbookImage(true);
        setTimeout(() => {
          setIsAddScrapbookImage(false);
        }, 1000);
        setCloseUrlImageModal(true);
        setTimeout(() => {
          setCloseUrlImageModal(false);
        }, 1000);
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDisabled(false);
    setIsLoading(false);
  }

  async function deleteImage(imgId) {
    // setIsLoading(true);
    setIsDisabled(true);

    console.log(imgId);
    console.log(frondId);

    try {
      const { data } = await axios.delete(
        `https://frond-admin.code-minds.tech/api/scrapbook-images/${frondId}/${imgId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(data);
      getScrapbookImages();
      if (data.status === 200) {
        setIsAddScrapbookImage(true);
        setTimeout(() => {
          setIsAddScrapbookImage(false);
        }, 1000);
        toast("Image deleted successfully", {
          autoClose: 1500,
          position: "bottom-right",
          hideProgressBar: true,
          className: "rounded-4xl custom-toast success-toast",
        });
      }
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDisabled(false);
    // setIsLoading(false);
  }

  const [frondData, setFrondData] = useState([]);

  const [isDataLoad, setIsDataLoad] = useState(false);

  const getScrapbookImages = async () => {
    setIsDataLoad(true);
    try {
      const { data } = await axios.get(
        `https://frond-admin.code-minds.tech/api/scrapbook-images/${frondId}`,
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setFrondData(data?.response?.data);
      console.log(data);
    } catch (error) {
      toast(error?.response?.data?.message, {
        autoClose: 1500,
        position: "bottom-right",
        hideProgressBar: true,
        className: "rounded-4xl custom-toast error-toast",
      });
    }
    setIsDataLoad(false);
  };

  useEffect(() => {
    getScrapbookImages();
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(addImage)}>
        <div
          className={`scrapbook-edit-img sm:min-h-[171px] sm:max-h-[171px] min-h-[171px] max-h-[171px] sm:min-w-[100%] sm:max-w-[100%] min-w-[100%] max-w-[100%] ${
            frondData?.files?.length > 0 ? "mb-5" : "mb-[50px]"
          }`}
        >
          <FilePond
            files={files}
            allowReorder={true}
            allowMultiple={false}
            allowFileEncode={false}
            onupdatefiles={setFiles}
            onpreparefile={(file, output) => {
              setUploadedFile(output);
            }}
            imageResizeTargetWidth={300}
            imageResizeTargetHeight={300}
            imageResizeUpscale={false}
            imageResizeMode="force"
            acceptedFileTypes={[
              "image/png",
              "image/jpeg",
              "image/gif",
              "image/bmp",
              "image/tiff",
              "image/webp",
            ]}
            name="file"
            allowImageEdit={false}
            labelIdle={imgTitle}
          />
        </div>
        {frondData?.files?.length > 0 && (
          <div className="grid md:grid-cols-4 grid-cols-2 items-center gap-2.5 mb-[65px]">
            {frondData?.files?.length > 0 &&
              frondData?.files?.map((item, idx) => (
                <div className="min-w-1/4 relative aspect-square">
                  <img
                    key={idx}
                    src={item?.image}
                    alt="Scrapbook Image"
                    className="size-full rounded-[10px]"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      deleteImage(item?.id);
                    }}
                    className="absolute right-[5px] top-[5px] z-20"
                  >
                    <img
                      src={removeImgIcon}
                      alt="Remove Image"
                      className="w-[28px] h-[28px]"
                    />
                  </button>
                </div>
              ))}
          </div>
        )}
        <ModalFooter className="shadow-modalShadow">
          <button
            disabled={isDisabled}
            type="submit"
            className="text-base font-bold leading-[18.4px] text-white flex justify-center items-center bg-mainGradiant rounded-[27px] py-[11px] px-[25px] w-[88px] sm:min-h-[39px] sm:max-h-[39px] min-h-[30px] max-h-[30px]"
          >
            {isLoading ? (
              <span className="flex justify-center items-center text-white">
                <i className="fa-solid fa-spinner fa-spin"></i>
              </span>
            ) : (
              "Save"
            )}
          </button>
        </ModalFooter>
      </form>
    </>
  );
};

export default EditImageTab;
